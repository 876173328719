import * as React from 'react';
import * as ReactDeviceDetect from 'react-device-detect';

import { BattlegearData, CreatureData, LocationData, MouseOverData, OnSelect } from '../interfaces';
import { CreatureArea } from './CreatureArea';
import { MirageLocation } from './MirageLocation';

export interface SpaceProps {
    id: number;
    onSelectCreature: OnSelect;
    onSelectBattlegear: OnSelect;
    onSelectMirage: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    owners: boolean[];
    tribes: string[][];
    mugicCounters: number[];
    creatures: CreatureData[];
    creaturesSelectable: boolean[];
    creaturesSelected: boolean[];
    creaturesHighlighted: boolean[];
    creaturesTargeted: boolean[];
    battlegear: BattlegearData[];
    battlegearSelectable: boolean[];
    battlegearSelected: boolean[];
    battlegearTargeted: boolean[];
    mirageLocation: LocationData;
    mirageSelectable: boolean;
    mirageTargeted: boolean;
    showing: string[];
}

export const Space = (props: SpaceProps) => {
    let className = 'space';

    if (props.creatures.length > 1) {
        className += ' multi';
        if (ReactDeviceDetect.engineName === 'WebKit') {
            className += ' webkit';
        }
    }

    const indices = props.creatures.reduce((indices, creature, index) => props.owners[index] ? [index, ...indices] : [...indices, index], []);
    let content: JSX.Element[] | JSX.Element;
    if (props.creatures.length > 0) {
        content = indices.map(index => (
            <CreatureArea
                key={index}
                onSelectCreature={props.onSelectCreature}
                onSelectBattlegear={props.onSelectBattlegear}
                onMouseOverCard={props.onMouseOverCard}
                owner={props.owners[index]}
                tribes={props.tribes[index]}
                mugicCounters={props.mugicCounters[index]}
                creature={props.creatures[index]}
                creatureSelectable={props.creaturesSelectable[index]}
                creatureSelected={props.creaturesSelected[index]}
                creatureHighlighted={props.creaturesHighlighted[index]}
                creatureTargeted={props.creaturesTargeted[index]}
                battlegear={props.battlegear[index]}
                battlegearSelectable={props.battlegearSelectable[index]}
                battlegearSelected={props.battlegearSelected[index]}
                battlegearTargeted={props.battlegearTargeted[index]}
                showing={props.showing[index]} />
        ));
    } else {
        content = (
            <CreatureArea
                onSelectCreature={props.onSelectCreature}
                onSelectBattlegear={props.onSelectBattlegear}
                onMouseOverCard={props.onMouseOverCard} />
        );
    }

    return (
        <div className={className} key={props.id}>
            {props.mirageLocation &&
                <MirageLocation
                    id={props.id}
                    onSelectMirage={props.onSelectMirage}
                    onMouseOverCard={props.onMouseOverCard}
                    showing={props.showing}
                    data={props.mirageLocation}
                    selectable={props.mirageSelectable}
                    targeted={props.mirageTargeted} />}
            {content}
        </div>
    );
};

Space.defaultProps = {
    mirageSelectable: false,
    mirageTargeted: false,
    mirageLocation: null,
};
