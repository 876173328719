import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { deleteSessionID, getSessionID, loggedIn } from '../helpers/local-storage';
import { UserData } from '../interfaces';
import { DiscordLink } from './DiscordLink';
import { FullScreenMessage } from './FullScreenMessage';
import { LoginRedirect } from './LoginRedirect';
import Logo from './Logo';
import { PatreonLink } from './PatreonLink';
import { UserSettingsButton } from './UserSettingsButton';
import MobileViewPort from './MobileViewPort';

export interface HomeProps { socket: WebSocket }
export interface HomeState {
    message: string;
    error: string;
    user: UserData;
}

export class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);

        this.state = {
            message: 'Connecting to Server',
            error: null,
            user: null,
        };

        this.props.socket.onmessage = this.onMessage.bind(this);

    }

    componentDidMount() {
        this.setState({ message: 'Fetching Home' }, () => {
            const sessionID = getSessionID();
            this.props.socket.send(`home|${sessionID}`);
        });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        if (data.logout) {
            deleteSessionID();
        }
        this.setState({
            message: null,
            error: data.error || this.state.error || null,
            user: data.user || this.state.user || null,
        });
    }

    setMessage(message: string) {
        this.setState({ message });
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        if (this.state.error) {
            return <FullScreenMessage message={this.state.error} />;
        }

        if (this.state.message) {
            return <FullScreenMessage message={this.state.message} />;
        }

        return (
            <div className={'home'}>
                <MobileViewPort />
                <UserSettingsButton {...{
                    socket: this.props.socket,
                    user: this.state.user,
                    setMessage: this.setMessage.bind(this),
                }}/>
                <Logo />
                <div className={'buttons'}>
                    <Link to={'/matchmaking'}>Matchmaking (Beta)</Link>
                    <Link to={'/creategame'}>Create Game</Link>
                    <Link to={'/deckbuilder'}>Deck Builder</Link>
                    <Link to={'/matchhistory'}>Match History</Link>
                    <Link to={'/banlists'}>Banlists</Link>
                    <a href={'https://chaoticbackup.github.io/collection/'} target="_blank" rel="noreferrer">Card List (Collection)</a>
                    <PatreonLink />
                </div>
                <DiscordLink />
            </div>
        );
    }
}
