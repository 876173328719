import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { DISCORD_CLIENT_ID, DISCORD_REDIRECT_URI, DISCORD_SCOPE } from '../App';
import { deleteLoginRedirectPath, getLoginRedirectPath, loggedIn, setOAuthState } from '../helpers/local-storage';
import { FullScreenMessage } from './FullScreenMessage';
import Logo from './Logo';

export const Login = () => {
    if (loggedIn()) {
        const loginRedirectLocation = getLoginRedirectPath();
        if (loginRedirectLocation) {
            deleteLoginRedirectPath();
            return <Redirect to={loginRedirectLocation} />;
        }
        return <Redirect to={'/'} />;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const accessCode = urlParams.get('code');
    if (accessCode !== null) {
        return <FullScreenMessage message={'Connecting to Discord'} />;
    }

    const clientID = DISCORD_CLIENT_ID;
    const scope = encodeURI(DISCORD_SCOPE);
    const oauthState = setOAuthState();
    const redirectURI = encodeURI(DISCORD_REDIRECT_URI);
    const discordAuthorizeLink = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${clientID}&scope=${scope}&state=${oauthState}&redirect_uri=${redirectURI}`;

    return (
        <div className={'login'}>
            <Logo />
            <a href={discordAuthorizeLink}>
                <FontAwesomeIcon icon={faDiscord} />
                <div>Login With Discord</div>
            </a>
        </div>
    );
};
