import * as React from 'react';
import { getSessionID } from '../helpers/local-storage';
import { UserData } from '../interfaces';
import { UserBadge } from './UserBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface UserSettingsButtonProps {
    socket: WebSocket;
    user: UserData;
    setMessage: (message: string) => void;
}

interface UserSettingsMenuProps {
    socket: WebSocket;
    user: UserData;
    setMessage: (message: string) => void;
    closeMenu: () => void;
}

const useClickOutside = (ref: React.MutableRefObject<HTMLDivElement>, close: () => void) => {
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, close]);
};

const languages = {
    en: { name: 'English', flag: '🇬🇧' },
    es: { name: 'Español', flag: '🇪🇸' },
};

const LanguageRow = ({ language, user, socket }: UserSettingsMenuProps & { language: string }) => {
    // I'm doing this to prevent users from clicking the same language multiple times, to account for server response delay
    const [selected, setSelected] = React.useState(user.language === language);
    React.useEffect(() => {
        setSelected(user.language === language);
    }, [user.language]);

    const setLanguage = () => {
        if (selected) return;
        setSelected(true);

        const sessionID = getSessionID();

        socket.send(`updateuser|${sessionID}|language|${language}`);
    };

    return (
        <div className={`language-option${selected ? " selected" : ""}`} onClick={setLanguage}>
            {selected && <FontAwesomeIcon icon={faCheck} />}
            {languages[language].flag} {languages[language].name}
        </div>
    );
}

const UserSettingsMenu = (props: UserSettingsMenuProps) => {
    const menuRef = React.useRef(null);
    useClickOutside(menuRef, props.closeMenu);

    const onLogout = (event: React.MouseEvent<HTMLDivElement>) => {
        props.setMessage('Logging Out');
        const sessionID = getSessionID();

        props.socket.send(`logout|${sessionID}`);
    };

    return (
        <div className={'user-settings-menu'} ref={menuRef}>
            <div className={'set-language'}>
                <LanguageRow {...props} language={'en'} />
                <LanguageRow {...props} language={'es'} />
            </div>
            <div className={'logout-button'} onClick={onLogout}>Logout</div>
        </div>
    );
};

export const UserSettingsButton = (props: UserSettingsButtonProps) => {
    const [open, setOpen] = React.useState(false);

    const openMenu = () => {
        setOpen(true);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    return (
        <div className={'user-settings-button'}>
            <UserBadge user={props.user} scale={1.5} right menu onClick={openMenu} />
            {open &&
                <UserSettingsMenu {...{ closeMenu, ...props }} />
            }
        </div>
    );
};
