import * as React from 'react';
import { useEffect, useRef } from 'react';

export interface LogsProps {
    logs: React.ReactNode[];
    className: React.ComponentProps<'div'>['className'];
}

export const ScrollList = ({ logs, className }: LogsProps) => {
    const scrollContainerRef = useRef(null);
    const isUserScrollingUp = useRef(false);
    const isProgrammaticScroll = useRef(false); // Persist flag across renders

    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;

      const handleScroll = () => {
        // Prevent setting scroll state immediately after programmatic scroll
        if (isProgrammaticScroll.current) {
          isProgrammaticScroll.current = false;
          return;
        }

        // Check if the user has scrolled up
        if (scrollContainer.scrollTop + scrollContainer.clientHeight > scrollContainer.scrollHeight - 2) {
          isUserScrollingUp.current = false;
        } else {
          isUserScrollingUp.current = true;
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;

      const scrollToBottom = () => {
        if (!isUserScrollingUp.current) {
          // Mark this scroll as programmatic
          isProgrammaticScroll.current = true;
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      };

      scrollToBottom();
    }, [logs]); // Runs when logs change

    return (
        <div className={className} ref={scrollContainerRef}>
            {logs.map((log, index) => <div key={index} className={'log'}>{log}</div>)}
        </div>
    );
};
