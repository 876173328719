import * as React from 'react';

import { useSocket } from './SocketContext';
import { ToggleSwitch } from './ToggleSwitch';

export interface GameSettings {
    [key: string]: {
        checked: boolean;
        label: string;
    }
}

export interface CustomSettingsProps {
    create?: boolean
    gameKey: string;
    gameSettings: GameSettings;
}

export type UpdateSettingsHandle = {
    updateGameSettings: () => void;
}

export const CustomSettings = React.forwardRef<UpdateSettingsHandle, CustomSettingsProps>((
   componentProps, ref
) => {
    const { gameSettings, create = false, gameKey } = componentProps;

    const socket = useSocket();
    const [settings, setSettings] = React.useState<GameSettings>({});

    React.useImperativeHandle(ref, () => ({
        updateGameSettings() {
            const options = Object.entries(settings).map(([key, setting]) => `${key}=${setting.checked ? 1 : 0}`).join(';');
            socket.send(`options|${gameKey}|${options}`);
        }
    }));

    React.useEffect(() => {
        setSettings(settings => ({ ...settings, ...gameSettings }));
    }, [gameSettings]);

    const toggleSetting = (key: string) => {
        const setting = settings[key];
        setSettings({ ...settings, [key]: { ...setting, checked: !setting.checked } });
    };

    return (
        <div className={'custom-settings'}>
            {!create &&
                <div>Game Settings</div>
            }
            <div className={'settings-options'}>
                {Object.entries(settings).map(([key, { label, checked }]) => (
                    <ToggleSwitch key={key} label={label} checked={checked} disabled={!create} onChange={() => toggleSetting(key)} />
                ))}
            </div>
        </div>
    );
});

