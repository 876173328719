import * as React from 'react';

import { AttackDiscardData, BoardContent, GeneralDiscardData } from '../interfaces';

export interface DiscardButtonsAreaProps {
    boardContent: BoardContent;
    ownGeneralDiscard: GeneralDiscardData;
    opponentsGeneralDiscard: GeneralDiscardData;
    attackDiscard: AttackDiscardData;
    opponentAttackDiscard: AttackDiscardData;
    onSetBoardContent: (boardContent: BoardContent) => void;
}

export class DiscardButtonsArea extends React.Component<DiscardButtonsAreaProps, {}> {
    constructor(props: DiscardButtonsAreaProps) {
        super(props);

        this.state = {};

        this.onSetBoardContent = this.onSetBoardContent.bind(this);
    }

    onSetBoardContent(boardContent: BoardContent): () => void {
        return () => this.props.onSetBoardContent(boardContent);
    }

    render() {
        let boardButtonClass = 'board-button discard-area-button border-button';
        let ownGeneralDiscardButtonClass = 'discard-button discard-area-button border-button';
        let opponentsGeneralDiscardButtonClass = 'opponent-discard-button discard-area-button border-button';
        let attackDiscardButtonClass = 'attack-discard-button discard-area-button border-button';
        let opponentAttackDiscardButtonClass = 'opponent-attack-discard-button discard-area-button border-button';
        const disabledClassName = ' disabled';
        let classNames = [
            boardButtonClass,
            ownGeneralDiscardButtonClass,
            opponentsGeneralDiscardButtonClass,
            attackDiscardButtonClass,
            opponentAttackDiscardButtonClass,
        ];

        let activeClass: string;
        if (this.props.boardContent === 'board') {
            activeClass = boardButtonClass;
        } else if (this.props.boardContent === 'general-discard') {
            activeClass = ownGeneralDiscardButtonClass;
        } else if (this.props.boardContent === 'opponent-general-discard') {
            activeClass = opponentsGeneralDiscardButtonClass;
        } else if (this.props.boardContent === 'attack-discard') {
            activeClass = attackDiscardButtonClass;
        } else if (this.props.boardContent === 'opponent-attack-discard') {
            activeClass = opponentAttackDiscardButtonClass;
        }
        classNames = classNames.map(className => className !== activeClass ? className + disabledClassName : className);

        const ownGeneralDiscardCount = this.props.ownGeneralDiscard.creatures.length
            + this.props.ownGeneralDiscard.battlegear.length
            + this.props.ownGeneralDiscard.mugic.length;
        const opponentsGeneralDiscardCount = this.props.opponentsGeneralDiscard.creatures.length
            + this.props.opponentsGeneralDiscard.battlegear.length
            + this.props.opponentsGeneralDiscard.mugic.length;

        return (
            <div className='discard-buttons-area'>
                <div className={classNames[3]} onClick={this.onSetBoardContent('attack-discard')}>
                    <div>Atk. Discard ({this.props.attackDiscard.attacks.length})</div>
                </div>
                <div className={classNames[1]} onClick={this.onSetBoardContent('general-discard')}>
                    <div>Gen. Discard ({ownGeneralDiscardCount})</div>
                </div>
                <div className={classNames[0]} onClick={this.onSetBoardContent('board')}>
                    <div>Board</div>
                </div>
                <div className={classNames[2]} onClick={this.onSetBoardContent('opponent-general-discard')}>
                    <div>Opp. Gen. Discard ({opponentsGeneralDiscardCount})</div>
                </div>
                <div className={classNames[4]} onClick={this.onSetBoardContent('opponent-attack-discard')}>
                    <div>Opp. Atk. Discard ({this.props.opponentAttackDiscard.attacks.length})</div>
                </div>
            </div>
        );
    }
};
