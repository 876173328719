import * as React from 'react';

import { AttackDiscardData, BurstElementData, GamePosition, MouseOverData } from '../interfaces';
import { Card } from './Card';
import { MouseMoveCapturer } from './MouseMoveCapturer';

export interface AttackDiscardProps {
    selected: GamePosition[];
    mouseOverBurstElement: BurstElementData;
    onMouseOverCard: (data: MouseOverData) => void;
    data: AttackDiscardData;
}

export const AttackDiscard = (props: AttackDiscardProps) => {
    return (
        <div className={'attack-discard'}>
            <div className={'attack-discard-cards'}>
                {props.data.attacks.map((attack, index) => (
                    <MouseMoveCapturer
                        key={index}
                        imageURL={attack.imageURL}
                        onMouseOverCard={props.onMouseOverCard}
                        data={attack}
                        type={'attack'}>
                        <Card data={attack} />
                    </MouseMoveCapturer>))}
            </div>
        </div>
    );
};
