import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { getShowDefaultDecks, toggleShowDefaultDecks } from '../helpers/local-storage';

interface DefaultDecksButtonProps {
    onClick: () => void;
}

export const DefaultDecksButton = (props: DefaultDecksButtonProps) => {
    const onClick = () => {
        toggleShowDefaultDecks();
        props.onClick();
    };

    return (
        <div className={`default-button ${getShowDefaultDecks() ? 'on' : 'off'}`}
            title={'Toggle Default Decks'}
            onClick={onClick}>
            <FontAwesomeIcon icon={faShieldAlt} />
        </div>
    );
};
