import * as React from 'react';

import { deckstringToValues, getDeckString } from '../helpers/deck';
import { DeckInfoData } from '../interfaces';
import { DeckPreview } from './DeckPreview';

export interface DeckEditorProps {
    socket: WebSocket;
    deckInfo: DeckInfoData;
    saved: boolean;
    updateDeck: (deckstring: string) => void;
    saveDeck: (id: number, name: string, deckstring: string, format: string) => void;
    back: () => void;
}

export interface DeckEditorState {
    name: string;
    format: string;
    values: {
        creatures: string;
        battlegear: string;
        mugic: string;
        locations: string;
        attacks: string;
    };
}

export class DeckEditor extends React.Component<DeckEditorProps, DeckEditorState> {
    constructor(props: DeckEditorProps) {
        super(props);

        if (props.deckInfo !== null) {
            const state = {
                name: props.deckInfo.name,
                format: props.deckInfo.format,
                values: {
                    creatures: '',
                    battlegear: '',
                    mugic: '',
                    locations: '',
                    attacks: '',
                },
            };
            if (props.deckInfo.deck !== null) {
                const deckstring = getDeckString(props.deckInfo.deck);
                state.values = deckstringToValues(deckstring);
            }
            this.state = state;
        } else {
            this.state = {
                name: '',
                format: 'standard',
                values: {
                    creatures: '',
                    battlegear: '',
                    mugic: '',
                    locations: '',
                    attacks: '',
                },
            };
        }

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeFormat = this.onChangeFormat.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    updateDeck() {
        this.props.updateDeck(this.getDeckString());
    }

    onSave(event: React.MouseEvent<HTMLDivElement>) {
        if (!(this.props.deckInfo && this.props.deckInfo.default === 1)) {
            this.props.saveDeck(this.props.deckInfo.id, this.state.name, this.getDeckString(), this.state.format);
        }
    }

    onBack(event: React.MouseEvent<HTMLDivElement>) {
        this.props.back();
    }

    clean(value: string) {
        return value.replace(/[^\w]/g, '').toLowerCase();
    }

    getDeckString() {
        const keys = Object.keys(this.state.values);
        return keys
            .map(key => {
                const cards = this.state.values[key]
                    .trim()
                    .split('\n')
                    .map(value => this.clean(value))
                    .filter(value => !keys.includes(value))
                    .join(' ');
                return `${key} ${cards}`;
            })
            .join(' ');
    }

    onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ name: event.target.value });
    }

    onChangeFormat(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ format: event.target.value });
    }

    onChange(key: string, value: string) {
        const values = { ...this.state.values };
        values[key] = value;
        this.setState({ values }, () => this.updateDeck());
    }

    getSaveButtonText() {
        if (this.props.deckInfo && this.props.deckInfo.default === 1) {
            return 'Default Deck';
        }
        if (this.props.saved) {
            return 'Saved';
        }
        return 'Save';
    }

    render() {
        const saveButtonText = this.getSaveButtonText();

        return (
            <div className={'deck-editor'}>
                {this.props.deckInfo.issues.length > 0 && (
                    <div className={'deck-editor-issues'}>
                        {this.props.deckInfo.issues.map((issue, index) =>
                            <div key={index} className={'issue'}>{issue}</div>)}
                    </div>
                )}
                <div className={'deck-editor-info-row'}>
                    <div className={'deck-editor-back-button'} onClick={this.onBack}>
                        <div>Back</div>
                    </div>
                    <input type='text' placeholder={'Name'} value={this.state.name} onChange={this.onChangeName} />
                    <div className={'deck-editor-save-area'}>
                        <select className={'deck-editor-format-selector'} value={this.state.format} onChange={this.onChangeFormat} disabled={this.props.deckInfo.default === 1}>
                            <option value='beta'>Beta</option>
                            <option value='standard'>Standard</option>
                            <option value='advanced_apprentice'>Advanced Apprentice</option>
                            <option value='unrestricted'>Unrestricted</option>
                            <option value='pauper'>Pauper</option>
                        </select>
                        <div className={'deck-editor-save-button'} onClick={this.onSave}>
                            <div>{saveButtonText}</div>
                        </div>
                    </div>
                </div>
                <DeckPreview deck={this.props.deckInfo.deck} />
                <div className={'deck-editor-sections'}>
                    {Object.keys(this.state.values).map((key, index) => {
                        const subdeck = this.props.deckInfo.deck[key];
                        const attacksInfo = key === 'attacks' ? ` - ${subdeck.reduce((buildPoints, attack) => buildPoints + attack.buildPoints, 0)} BP`: '';
                        const infoString = subdeck.length > 0 ? ` (${subdeck.length})${attacksInfo}` : '';

                        return (
                            <div className={'deck-editor-section'} key={index}>
                                <div className={'title'}>{`${key}${infoString}`}</div>
                                <textarea value={this.state.values[key]} onChange={((event: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    this.onChange(key, event.target.value))}></textarea>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
