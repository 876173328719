import * as React from 'react';
import { EmoteData, EmoteMap } from '../interfaces';
const { useEffect, useState } = React;

const fadeDuration = .5 * 1000;
const duration = 3 * 1000;

let p1TimerId: NodeJS.Timeout;
let p2TimerId: NodeJS.Timeout;

interface EmoteDisplayProps {
    emoteData: EmoteData;
    emoteMap: EmoteMap;
    clearEmote: (p: string) => void;
}

export const EmoteDisplay = (props: EmoteDisplayProps) => {
    const { emoteData, emoteMap, clearEmote } = props;
    const [e1, setE1] = useState(false);
    const [e2, setE2] = useState(false);
    const [p1EmoteImage, setP1EmoteImage] = useState(null);
    const [p2EmoteImage, setP2EmoteImage] = useState(null);

    useEffect(() => {
        if (emoteData.p1 === undefined) return;
        clearTimeout(p1TimerId);

        setP1EmoteImage(emoteMap[emoteData.p1]);
        setE1(true);

        p1TimerId = setTimeout(() => {
            p1TimerId = setTimeout(() => {
                setP1EmoteImage(null);
            }, fadeDuration);
            setE1(false);
        }, duration - fadeDuration);

        clearEmote('p1');
    }, [emoteData.p1]);

    useEffect(() => {
        if (emoteData.p2 === undefined) return;
        clearTimeout(p2TimerId);

        setP2EmoteImage(emoteMap[emoteData.p2]);
        setE2(true);

        p2TimerId = setTimeout(() => {
            p2TimerId = setTimeout(() => {
                setP2EmoteImage(null);
            }, fadeDuration);
            setE2(false);
        }, duration - fadeDuration);

        clearEmote('p2');
    }, [emoteData.p2]);

    return (
        <div className="emote-display">
            <div
                className={`emote-image ${e1 ? 'animate-in' : 'animate-out'}`}
                style={(p1EmoteImage !== null) ? null : { display: 'none' }}
            >
                {(p1EmoteImage !== null) && <div style={{ backgroundImage: `url('${p1EmoteImage}')` }} />}
            </div>
            <div
                className={`emote-image ${e2 ? 'animate-in' : 'animate-out'}`}
                style={(p2EmoteImage !== null) ? null: { display: 'none' }}
            >
                {(p2EmoteImage !== null) && <div style={{ backgroundImage: `url('${p2EmoteImage}')` }} />}
            </div>
        </div>
    );
};
