import * as React from 'react';

import * as rings from '../assets/rings.png';

export interface LogoState {
    iter: number;
    setChars: number;
    code: string;
}

const chars = '0123456789ABCDEF';
const recode = 'RECODE';
const interval = 90;
const setCharIters = [1 * interval, 2 * interval, 3 * interval, 3.5 * interval, 4 * interval, 4.5 * interval];

export default class Logo extends React.Component<{}, LogoState> {
    interval: number;

    constructor(props: {}) {
        super(props);

        this.state = {
            iter: 0,
            setChars: 0,
            code: this.getCode(0),
        };

        this.interval = null;
    }

    updateCode() {
        let newState: LogoState = { ...this.state, iter: this.state.iter + 1 };
        if (this.state.iter >= setCharIters[this.state.setChars]) {
            newState = { ...newState, setChars: this.state.setChars + 1 };
        }
        newState = { ...newState, code: this.getCode(newState.setChars) };
        this.setState(newState, () => {
            if (this.state.setChars >= recode.length) {
                window.clearInterval(this.interval);
            }
        });
    }

    getCode(setChars: number) {
        let code = recode.substr(0, setChars);
        for (let i = setChars; i < 6; i++) {
            code += chars[Math.floor(Math.random() * chars.length)];
        }
        return code;
    }

    componentDidMount() {
        this.interval = window.setInterval(() => this.updateCode(), 10);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    render() {
        return (
            <div className={'logo'}>
                <div className={'title'} style={{ backgroundImage: `url(/${rings})` }}>
                    <div>Chaotic</div>
                </div>
                <div className={'code'}>{this.state.code}</div>
            </div>
        );
    }
}
