import * as React from 'react';

import * as airActive from '../assets/air-active.png';
import * as airInactive from '../assets/air-inactive.png';
import * as earthActive from '../assets/earth-active.png';
import * as earthInactive from '../assets/earth-inactive.png';
import * as fireActive from '../assets/fire-active.png';
import * as fireInactive from '../assets/fire-inactive.png';
import * as waterActive from '../assets/water-active.png';
import * as waterInactive from '../assets/water-inactive.png';

export interface CreatureInfoElementProps { element: string; active: boolean }

export const CreatureInfoElement = (props: CreatureInfoElementProps) => {
    let image: string;
    switch (props.element) {
        case 'fire':
            image = props.active ? fireActive : fireInactive;
            break;
        case 'air':
            image = props.active ? airActive : airInactive;
            break;
        case 'earth':
            image = props.active ? earthActive : earthInactive;
            break;
        case 'water':
            image = props.active ? waterActive : waterInactive;
            break;
    }

    return (
        <div className={'creature-info-element'}>
            <div className={'creature-info-element-image'} style={{ backgroundImage: `url(/${image})` }} />
        </div>
    );
};
