import { faArrowLeft, faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { UserData } from '../interfaces';

export interface UserAvatarProps {
    className?: React.ComponentProps<'div'>['className']
    user: UserData;
    scale: number;
}

export const UserAvatar = (props: UserAvatarProps) => {
    const { user, scale } = props;

    const avatarStyle: React.CSSProperties = { width: `${32 * scale}px`, height: `${32 * scale}px` };
    if (user.discordUserID && user.avatar) {
        avatarStyle.backgroundImage = `url('https://cdn.discordapp.com/avatars/${user.discordUserID}/${user.avatar}.png?size=${32 * scale}')`;
    } else if (user.discriminator) {
        const discriminator = (parseInt(user.discriminator) || 0) % 5;
        avatarStyle.backgroundImage = `url('https://cdn.discordapp.com/embed/avatars/${discriminator}.png?size=${32 * scale}')`;
    }

    return <div className={props.className} style={avatarStyle}></div>;
};

export interface UserBadgeProps {
    user: UserData;
    scale: number;
    right: boolean;
    activePlayer: boolean;
    menu: boolean;
    emoteIcon?: JSX.Element;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export const UserBadge = (props: UserBadgeProps) => {
    const { user, scale, right, activePlayer, emoteIcon, onClick } = props;

    if (user === null) {
        return null;
    }

    const userBadgeStyle: React.CSSProperties = { minWidth: `${160 * scale + 10}px`, height: `${32 * scale}px` };

    const usernameStyle: React.CSSProperties = { width: `${128 * scale}px`, fontSize: `${0.8 * scale}em` };
    if (right) {
        usernameStyle.textAlign = 'right';
    }

    const avatar = <UserAvatar key={'avatar'} className={'avatar'} user={user} scale={scale} />;
    const username = <div key={'username'} className={'username'} style={usernameStyle}>{user.username}</div>;
    const activeArrow = activePlayer ? (right ? <FontAwesomeIcon key={'active-arrow'} icon={faArrowRight} /> : <FontAwesomeIcon key={'active-arrow'} icon={faArrowLeft} />) : null;
    const menuIcon = props.menu ? <FontAwesomeIcon key={'menu'} icon={faChevronDown} /> : null;

    return (
        <div className={'user-badge'} style={userBadgeStyle} onClick={onClick}>
            {right ? [activeArrow, emoteIcon, username, avatar, menuIcon] : [avatar, username, emoteIcon, activeArrow]}
        </div>
    );
};

UserBadge.defaultProps = {
    scale: 1,
    right: false,
    menu: false,
    activePlayer: false,
    onClick: () => {},
};
