import * as React from 'react';

import { BattlegearData } from '../interfaces';
import { CardImage } from './CardImage';
import { TargetIcon } from './TargetIcon';

export interface BattlegearProps { data: BattlegearData; targeted: boolean }

export const Battlegear = (props: BattlegearProps) => {
    const className = 'battlegear' + (props.data !== null ? ' filled' : '') + (props.data && props.data.owner ? ' owner' : '');
    return (
        <div className={className}>
            {props.data && props.data.faceUp
                && <CardImage name={props.data && props.data.name} imageURL={props.data && props.data.imageURL} />}
            {props.targeted && <TargetIcon />}
        </div>
    );
};

Battlegear.defaultProps = {
    targeted: false,
};
