import * as React from 'react';
import { ViewDeck } from '../interfaces';

export interface DeckInfoAreaProps {
    spectate: boolean;
    ownAttackDeckCount: number;
    opponentAttackDeckCount: number;
    ownLocationDeckCount: number;
    opponentLocationDeckCount: number;
    onViewDeck: (deck: ViewDeck) => void;
}

export const DeckInfoArea = (props: DeckInfoAreaProps) => {
    return (
        <div className={'deck-info-area' + (props.spectate ? '' : ' clickable')}>
            <div title={'Location Deck'} onClick={() => props.onViewDeck('ownLocationDeck')}>
                <div className={'location-icon'} />
                <div>{props.ownLocationDeckCount}</div>
            </div>
            <div title={'Attack Deck'} onClick={() => props.onViewDeck('ownAttackDeck')}>
                <div className={'attack-icon'} />
                <div>{props.ownAttackDeckCount}</div>
            </div>
            <div title={'Opponent\'s Attack Deck'} onClick={() => props.onViewDeck('opponentAttackDeck')}>
                <div>{props.opponentAttackDeckCount}</div>
                <div className={'attack-icon'} />
            </div>
            <div title={'Opponent\'s Location Deck'} onClick={() => props.onViewDeck('opponentLocationDeck')}>
                <div>{props.opponentLocationDeckCount}</div>
                <div className={'location-icon'} />
            </div>
        </div>
    );
};
