import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ScrollList } from './ScrollList';

export interface LogsProps {
    data: React.ReactNode[];
}

export const Logs = (props: LogsProps) => {
    const logs = props.data.slice();

    return (
        <ScrollList logs={logs} className={'logs'} />
    );
};
