import * as React from 'react';

import { CardData, DeckData, MouseOverData } from '../interfaces';
import { Card } from './Card';
import { CardImage } from './CardImage';
import { CardViewer } from './CardViewer';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { MugicBoard } from './MugicBoard';
import { Space } from './Space';

export interface DeckPreviewProps { deck: DeckData }
export interface DeckPreviewState { mouseOverData: MouseOverData }

export class DeckPreview extends React.Component<DeckPreviewProps, DeckPreviewState> {
    constructor(props: DeckPreviewProps) {
        super(props);

        this.state = { mouseOverData: { x: null, y: null, imageURL: '', data: null, type: '' } };

        this.onMouseOverCard = this.onMouseOverCard.bind(this);
    }

    onMouseOverCard(data: MouseOverData) {
        this.setState({ mouseOverData: data });
    }

    render() {
        const columns: number[][] = [[0], [1, 2], [3, 4, 5]];
        const attackColumns = this.props.deck.attacks.reduce((attackColumns, attack) => {
            if (attackColumns.length === 0 || attackColumns[attackColumns.length - 1].length === 10) {
                attackColumns.push([]);
            }
            attackColumns[attackColumns.length - 1].push(attack);
            return attackColumns;
        }, [] as CardData[][]);

        return (
            <div className={'deck-preview'}>
                <MugicBoard data={this.props.deck.mugic} onMouseOverCard={this.onMouseOverCard} left />
                <div className={'main-area thicc-border-area'}>
                    <div className={'preview-area'}>
                        <div className={'deck-preview-locations'}>
                            {this.props.deck.locations.length > 0 && this.props.deck.locations.map((location, index) => (
                                <MouseMoveCapturer
                                    key={index}
                                    data={location}
                                    imageURL={location.imageURL}
                                    onMouseOverCard={this.onMouseOverCard}
                                    type={'location'}>
                                    <div className={'location'}>
                                        <CardImage name={location.name} imageURL={location.imageURL} />
                                    </div>
                                </MouseMoveCapturer>))}
                        </div>
                        <div className={'deck-preview-creatures'}>
                            {columns.map((column, index) => (
                                <div key={index} className={'column'}>
                                    {column.map((deckIndex, index) => {
                                        const creature = this.props.deck.creatures[deckIndex] || null;
                                        const battlegear = this.props.deck.battlegear[deckIndex] || null;

                                        return (
                                            <Space
                                                key={index}
                                                id={index}
                                                onSelectMirage={(id: number) => {}}
                                                onSelectCreature={(id: number) => {}}
                                                onSelectBattlegear={(id: number) => {}}
                                                onMouseOverCard={this.onMouseOverCard}
                                                owners={[true]}
                                                tribes={[creature ? creature.tribes : ['Generic']]}
                                                mugicCounters={[creature ? creature.mugicCounters : 0]}
                                                creatures={[creature]}
                                                creaturesSelectable={[false]}
                                                creaturesSelected={[false]}
                                                creaturesHighlighted={[false]}
                                                creaturesTargeted={[false]}
                                                battlegear={[battlegear]}
                                                battlegearSelectable={[false]}
                                                battlegearSelected={[false]}
                                                battlegearTargeted={[false]}
                                                showing={[null]}/>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className={'deck-preview-attacks'}>
                            {attackColumns.map((column, index) => (
                                <div key={index} className={'column'}>
                                    {column.map((attack, index) => (
                                        <MouseMoveCapturer
                                            key={index}
                                            data={attack}
                                            imageURL={attack.imageURL}
                                            onMouseOverCard={this.onMouseOverCard}
                                            type={'attack'}>
                                            <Card data={attack} />
                                        </MouseMoveCapturer>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <MugicBoard data={[]} onMouseOverCard={this.onMouseOverCard} />
                {this.state.mouseOverData.imageURL.length > 0 && <CardViewer alt data={this.state.mouseOverData} />}
            </div>
        );
    }
};
