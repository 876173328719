import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface ConcedeButtonProps {
    onConcede: () => void;
}

export class ConcedeButton extends React.Component<ConcedeButtonProps> {
    constructor(props: ConcedeButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event: React.MouseEvent<HTMLDivElement>) {
        if (confirm('Do you want to concede?')) {
            this.props.onConcede();
        }
    }

    render() {
        return (
            <div className={'concede-button border-button'} title={'Concede'} onClick={this.onClick}>
                <FontAwesomeIcon icon={faFlag} />
            </div>
        );
    }
}
