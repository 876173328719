import * as React from 'react';

import { LocationData } from '../interfaces';
import { CardImage } from './CardImage';

export interface LocationCardProps { data: LocationData }

export const LocationCard = (props: LocationCardProps) => {
    const className = 'location' + (props.data && props.data.owner ? ' owner' : '');
    return (
        <div className={className}>
            <CardImage name={props.data.name} imageURL={props.data.imageURL} />
        </div>
    );
};
