import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const HomeButton = () => (
    <Link className={'home-button'} to={'/'}>
        <FontAwesomeIcon icon={faHome} />
    </Link>
);
