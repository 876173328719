import * as React from 'react';

import { CreatureData } from '../interfaces';
import { CreatureInfoElements } from './CreatureInfoElements';
import { CreatureInfoEnergy } from './CreatureInfoEnergy';
import { CreatureInfoStats } from './CreatureInfoStats';

export interface CreatureInfoProps { data: CreatureData }

export const CreatureInfo = (props: CreatureInfoProps) => {
    return (
        <div className={'creature-info'}>
            <div className={'title'}>
                {props.data !== null && props.data.name}
            </div>
            <CreatureInfoElements data={props.data} />
            <CreatureInfoStats data={props.data} />
            <CreatureInfoEnergy data={props.data} />
        </div>
    );
};
