import * as React from 'react';

import { ChoiceData, MouseOverData, MugicData, OnSelect } from '../interfaces';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { MugicCounter } from './MugicCounter';
import { Selectable } from './Selectable';

export interface MugicBoardProps {
    left: boolean;
    action: string;
    choices: ChoiceData[];
    onSelect: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    owner: boolean;
    data: MugicData[];
}

export const MugicBoard = (props: MugicBoardProps) => {
    const className = 'mugic-board thicc-border-area'
        + (props.left ? ' left' : ' right')
        + (props.owner && props.action === 'priority'
            || props.choices.some(choice => choice.position && choice.position.type === 'mugic') ? ' highlighted' : '');

    return (
        <div className={className}>
            {props.data && props.data.map((mugic, index) => {
                const selectable = props.owner
                    && props.action === 'priority'
                    || props.choices.some(choice =>
                        choice.position
                            && choice.position.type === 'mugic'
                            && choice.position.id === mugic.id);

                return (
                    <Selectable
                        key={index}
                        id={mugic.id}
                        selectable={selectable}
                        onSelect={props.onSelect}>
                        <MouseMoveCapturer imageURL={mugic.imageURL} onMouseOverCard={props.onMouseOverCard} data={mugic} type={'mugic'}>
                            <MugicCounter size={30} tribes={[mugic.tribe]} />
                        </MouseMoveCapturer>
                    </Selectable>
                );
            })}
        </div>
    );
};

MugicBoard.defaultProps = {
    left: false,
    action: '',
    choices: [],
    onSelect: (id: number) => {},
    owner: false,
};
