import * as React from 'react';

import { CreatureData, MouseOverData } from '../interfaces';
import { CreatureInfo } from './CreatureInfo';

export interface CreatureInfoAreaProps {
    reversed: boolean;
    mouseOverData: MouseOverData;
    engaged: [CreatureData, CreatureData];
}

export const CreatureInfoArea = (props: CreatureInfoAreaProps) => {
    let content: JSX.Element | JSX.Element[] = null;
    if (props.mouseOverData && props.mouseOverData.type === 'creature') {
        content = <CreatureInfo data={props.mouseOverData.data as CreatureData} />;
    } else if (props.engaged.some(creature => creature !== null)) {
        content = (props.reversed ? props.engaged.slice().reverse() : props.engaged)
            .filter(creature => creature !== null)
            .map((creature, index) => <CreatureInfo key={index} data={creature} />);
    } else {
        content = null;
    }
    return (
        <div className={'creature-info-area'}>
            {content}
        </div>
    );
};
