import * as React from 'react';
import { EmoteMap } from '../interfaces';
const { useMemo, useRef, useEffect } = React;

interface EmoteDropDownProps {
    emoteChoices: number[];
    emoteMap: EmoteMap;
    onEmoteSelect: (emote: number) => void;
    closeEmoteDropDown: () => void;
}

const useClickOutside = (ref: React.MutableRefObject<HTMLDivElement>, closeEmoteDropDown: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
                closeEmoteDropDown();
            }
        };

        const handleScroll = () => {closeEmoteDropDown();};

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('scroll', handleScroll);
        document.querySelector('#app .game').addEventListener('scroll', handleScroll);

        // Unbind the event listener on clean up
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleScroll);
            document.querySelector('#app .game').removeEventListener('scroll', handleScroll);
        };
    }, [ref, closeEmoteDropDown]);
};

export const EmoteDropDown = (props: EmoteDropDownProps) => {
    const { emoteChoices, emoteMap, onEmoteSelect, closeEmoteDropDown } = props;
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef, closeEmoteDropDown);

    const selectEmote = (emoteId: number) => {
        closeEmoteDropDown();
        onEmoteSelect(emoteId);
    };

    const emotes = useMemo(() => emoteChoices.map((emoteId => (
        <div key={emoteId} className="emote-choice" onClick={() => selectEmote(emoteId)} >
            <div style={{ backgroundImage: `url('${emoteMap[emoteId]}')` }} />
        </div>
    ))), [emoteMap]);

    return (
        <div className={'emote-select-box'} ref={wrapperRef}>
            {emotes}
        </div>
    );
};
