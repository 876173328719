import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import { BurstElementData, EffectData, GameData, GamePosition, MouseOverData, OnSelect, UserData } from '../interfaces';
import { Burst } from './Burst';
import { Chat } from './Chat';
import { Logs } from './Logs';
import { UserAvatar } from './UserBadge';

const BURST = 'burst';
const LOGS = 'logs';
const CHAT = 'chat';
const SPECTATORS = 'spectators';

export interface BurstAreaProps {
    action: string;
    targeting: EffectData;
    selected: GamePosition[];
    mouseOverTargets: GamePosition[];
    onSubmitChat: (chat: string) => void;
    onSelectBurstElement: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    burst: BurstElementData[];
    resolving: BurstElementData;
    logs: string[];
    chatMessages: string[];
    spectate: boolean;
    ownUser: UserData;
    opponentUser: UserData;
    spectators: UserData[];
}

export interface BurstAreaState {
    showing: string;
    newChat: boolean;
    newSpectator: boolean;
}

export class BurstArea extends React.Component<BurstAreaProps, BurstAreaState> {
    constructor(props: BurstAreaProps) {
        super(props);

        this.state = { showing: BURST, newChat: false, newSpectator: false };

        this.onClickBurstTitle = this.onClickBurstTitle.bind(this);
        this.onClickLogsTitle = this.onClickLogsTitle.bind(this);
        this.onClickChatTitle = this.onClickChatTitle.bind(this);
        this.onClickSpectatorsTitle = this.onClickSpectatorsTitle.bind(this);
    }

    componentDidUpdate(prevProps: BurstAreaProps, prevState: BurstAreaState) {
        if (prevProps.chatMessages.length < this.props.chatMessages.length
            && this.state.showing !== CHAT) {
            this.setState({ newChat: true });
        }
        if (prevProps.spectators.length < this.props.spectators.length
            && this.state.showing !== SPECTATORS) {
            this.setState({ newSpectator: true });
        }
    }

    onClickBurstTitle() {
        this.setState({ showing: BURST });
    }

    onClickLogsTitle() {
        this.setState({ showing: LOGS });
    }

    onClickChatTitle() {
        this.setState({ showing: CHAT, newChat: false });
    }

    onClickSpectatorsTitle() {
        this.setState({ showing: SPECTATORS, newSpectator: false });
    }

    getContent() {
        if (this.state.showing === BURST) {
            return <Burst
                action={this.props.action}
                targeting={this.props.targeting}
                selected={this.props.selected}
                mouseOverTargets={this.props.mouseOverTargets}
                onSelectBurstElement={this.props.onSelectBurstElement}
                onMouseOverCard={this.props.onMouseOverCard}
                burst={this.props.burst}
                resolving={this.props.resolving} />;
        } else if (this.state.showing === LOGS) {
            return <Logs data={this.props.logs} />;
        } else if (this.state.showing === SPECTATORS) {
            return <Logs data={this.props.spectators.map(spectator => (
                <div key={spectator.discordUserID} className='spectators'>
                    <UserAvatar className="avatar" user={spectator} scale={.5}/>
                    <div>{spectator.username}</div>
                </div>
            ))} />;
        } else if (this.state.showing === CHAT) {
            return <Chat
                chatMessages={this.props.chatMessages}
                spectate={this.props.spectate}
                onSubmitChat={this.props.onSubmitChat}
                ownUser={this.props.ownUser}
                opponentUser={this.props.opponentUser}
            />;
        }
    }

    render() {
        return (
            <div className='burst-area thicc-border-area'>
                {this.getContent()}
                <div className={'burst-titles'}>
                    <div className={'title' + (this.state.showing === BURST ? '' : ' disabled')} onClick={this.onClickBurstTitle}>Burst</div>
                    <div className={'title' + (this.state.showing === LOGS ? '' : ' disabled')} onClick={this.onClickLogsTitle}>Logs</div>
                    <div className={'title' + (this.state.showing === SPECTATORS ? '' : ' disabled') + (this.state.newSpectator ? ' newchat' : '')} onClick={this.onClickSpectatorsTitle}><FontAwesomeIcon icon={faEye} /> {this.props.spectators.length}</div>
                    <div className={'title' + (this.state.showing === CHAT ? '' : ' disabled') + (this.state.newChat ? ' newchat' : '')} onClick={this.onClickChatTitle}>Chat</div>
                </div>
            </div>
        );
    }
}
