import * as React from 'react';

import {
    AttackDiscardData,
    BoardContent,
    BoardData,
    BurstElementData,
    ChoiceData,
    EffectData,
    GamePosition,
    GeneralDiscardData,
    MouseOverData,
    MugicData,
    OnSelect,
} from '../interfaces';
import { AttackDiscard } from './AttackDiscard';
import { Board } from './Board';
import { GeneralDiscard } from './GeneralDiscard';
import { MugicBoard } from './MugicBoard';

export interface BoardAreaProps {
    boardContent: BoardContent;
    action: string;
    reversed: boolean;
    combat: boolean;
    hive: boolean;
    board: BoardData;
    creatureBeingDefendedID: number;
    creatureAttackingID: number;
    ownGeneralDiscard: GeneralDiscardData;
    opponentsGeneralDiscard: GeneralDiscardData;
    attackDiscard: AttackDiscardData;
    opponentAttackDiscard: AttackDiscardData;
    choices: ChoiceData[];
    mugic: MugicData[];
    opponentMugic: MugicData[];
    targeting: EffectData;
    selected: GamePosition[];
    mouseOverBurstElement: BurstElementData;
    onSelectSpace: OnSelect;
    onSelectCreature: OnSelect;
    onSelectBattlegear: OnSelect;
    onSelectMugic: OnSelect;
    onSelectMirage: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
}

export const BoardArea = (props: BoardAreaProps) => {
    let content: JSX.Element;
    if (props.boardContent === 'board') {
        content = (<Board
            action={props.action}
            choices={props.choices}
            targeting={props.targeting}
            selected={props.selected}
            mouseOverBurstElement={props.mouseOverBurstElement}
            onSelectSpace={props.onSelectSpace}
            onSelectCreature={props.onSelectCreature}
            onSelectBattlegear={props.onSelectBattlegear}
            onSelectMirage={props.onSelectMirage}
            onMouseOverCard={props.onMouseOverCard}
            reversed={props.reversed}
            combat={props.combat}
            hive={props.hive}
            data={props.board}
            creatureBeingDefendedID={props.creatureBeingDefendedID} 
            creatureAttackingID={props.creatureAttackingID}
        />);
    } else if (props.boardContent === 'general-discard') {
        content = (<GeneralDiscard
            selected={props.selected}
            mouseOverBurstElement={props.mouseOverBurstElement}
            onMouseOverCard={props.onMouseOverCard}
            data={props.ownGeneralDiscard} />);
    } else if (props.boardContent === 'opponent-general-discard') {
        content = (<GeneralDiscard
            selected={props.selected}
            mouseOverBurstElement={props.mouseOverBurstElement}
            onMouseOverCard={props.onMouseOverCard}
            data={props.opponentsGeneralDiscard} />);
    } else if (props.boardContent === 'attack-discard') {
        content = (<AttackDiscard
            selected={props.selected}
            mouseOverBurstElement={props.mouseOverBurstElement}
            onMouseOverCard={props.onMouseOverCard}
            data={props.attackDiscard} />);
    } else if (props.boardContent === 'opponent-attack-discard') {
        content = (<AttackDiscard
            selected={props.selected}
            mouseOverBurstElement={props.mouseOverBurstElement}
            onMouseOverCard={props.onMouseOverCard}
            data={props.opponentAttackDiscard} />);
    }

    return (
        <div className={'board-area'}>
            <MugicBoard
                left
                action={props.action}
                choices={props.choices}
                onSelect={props.onSelectMugic}
                onMouseOverCard={props.onMouseOverCard}
                owner
                data={props.mugic} />
            <div className={'main-area thicc-border-area'}>
                {content}
            </div>
            <MugicBoard
                onMouseOverCard={props.onMouseOverCard}
                data={props.opponentMugic} />
        </div>
    );
};
