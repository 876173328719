import * as React from 'react';

import { BurstElementData, CardData, EffectData, GamePosition, MouseOverData, OnSelect } from '../interfaces';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { Selectable } from './Selectable';
import { TargetIcon } from './TargetIcon';

export interface BurstProps {
    action: string;
    targeting: EffectData;
    selected: GamePosition[];
    mouseOverTargets: GamePosition[];
    onSelectBurstElement: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    burst: BurstElementData[];
    resolving: BurstElementData;
}

export const Burst = (props: BurstProps) => {
    return (
        <div className={'burst'}>
            {props.burst.map((element, index) => {
                let selectable = false;
                if (props.action === 'target') {
                    selectable = ['AttackBurstElement', 'BurstElement', 'MugicBurstElement', 'ActivatedCreatureAbilityBurstElement', 'ActivatedBattlegearAbilityBurstElement', 'ActivatedLocationAbilityBurstElement']
                        .includes(props.targeting.targetTypes[props.selected.length]);
                }
                const selected = props.selected
                    .filter(selected => selected.type === 'burst')
                    .map(selected => selected.id)
                    .includes(index);
                const highlighted = props.resolving !== null && index === props.burst.length - 1;
                const targeted = props.mouseOverTargets
                    .filter(target => target.type === 'burst')
                    .map(target => target.id)
                    .includes(index);

                const imageURL = element.type === 'attack' || element.type === 'mugic'
                    ? (element.value as CardData).imageURL
                    : element.source.imageURL;

                return (
                    <Selectable
                        key={index}
                        id={index}
                        selectable={selectable}
                        selected={selected}
                        highlighted={highlighted}
                        onSelect={props.onSelectBurstElement}>
                        <MouseMoveCapturer
                            imageURL={imageURL}
                            onMouseOverCard={props.onMouseOverCard}
                            data={element}
                            type={'burst'}>
                            <div key={index} className={'burst-element'}>
                                {element.value.name}
                                {targeted && <TargetIcon />}
                            </div>
                        </MouseMoveCapturer>
                    </Selectable>
                );
            })}
        </div>
    );
};
