import * as React from 'react';

import { tribeColor } from '../helpers/tribe-color';

export interface MugicCounterProps { size: number; tribes: string[]; fill: boolean; owner: boolean }

export const MugicCounter = (props: MugicCounterProps) => {
    const colors = props.tribes.map(tribe => tribeColor(tribe));
    const fillColor = (i: number) => props.fill ? colors[i] : 'none';

    const polygons = [] as React.SVGProps<SVGPolygonElement>[];
    if (props.tribes.length === 1) {
        polygons.push(<polygon key={0} points='180,0 37,72 0,232 98,360 262,360 360,232 323,72' style={{ fill: fillColor(0) }} />);
    } else if (props.tribes.length === 2) {
        polygons.push(<polygon key={0} points='180,0 37,72 0,232 98,360 180,360' style={{ fill: fillColor(0) }} />);
        polygons.push(<polygon key={1} points='180,360 262,360 360,232 323,72 180,0' style={{ fill: fillColor(1) }} />);
    } else if (props.tribes.length === 3) {
        polygons.push(<polygon key={0} points='180,180 0,232 98,360 262,360 360,232' style={{ fill: fillColor(0) }} />);
        polygons.push(<polygon key={1} points='180,0 37,72 0,232 180,180' style={{ fill: fillColor(1) }} />);
        polygons.push(<polygon key={2} points='180,0 180,180 360,232 323,72' style={{ fill: fillColor(2) }} />);
    } else if (props.tribes.length === 4) {
        polygons.push(<polygon key={0} points='180,0 37,72 180,180 323,72' style={{ fill: fillColor(0) }} />);
        polygons.push(<polygon key={1} points='37,72 0,232 98,360 180,180' style={{ fill: fillColor(1) }} />);
        polygons.push(<polygon key={2} points='98,360 262,360 180,180' style={{ fill: fillColor(2) }} />);
        polygons.push(<polygon key={3} points='180,180 262,360 360,232 323,72' style={{ fill: fillColor(3) }} />);
    } else if (props.tribes.length === 5) {
        polygons.push(<polygon key={0} points='180,0 37,72 18,152 180,180 323,72' style={{ fill: fillColor(0) }} />);
        polygons.push(<polygon key={1} points='18,152 0,232 98,360 180,180' style={{ fill: fillColor(1) }} />);
        polygons.push(<polygon key={2} points='98,360 262,360 262,360 180,180' style={{ fill: fillColor(2) }} />);
        polygons.push(<polygon key={3} points='262,360 360,232 323,72 180,180' style={{ fill: fillColor(3) }} />);
        polygons.push(<polygon key={4} points='180,0 323,72 341.5,152 180,180' style={{ fill: fillColor(4) }} />);
    }

    const stroke = props.fill ? (props.owner ? 'black' : 'white') : colors[0];

    return (
        <svg width={props.size} height={props.size} viewBox='-20 -20 400 400'>
            {polygons}
            <polygon key={5} points='180,0 37,72 0,232 98,360 262,360 360,232 323,72' style={{ fill: 'none', stroke: stroke, strokeWidth: 40 }} />
        </svg>
    );
};

MugicCounter.defaultProps = {
    fill: false,
    owner: false,
};
