import * as React from 'react';

export interface CardImageProps { name: string; imageURL: string }

export const CardImage = (props: CardImageProps) => {
    const style: React.CSSProperties = {};
    if (props.imageURL && props.imageURL.length > 0) {
        style.backgroundImage = `url('${props.imageURL}')`;
    }

    return (
        <div className={'card-image'} style={style}>
            {(!props.imageURL || props.imageURL.length === 0) && props.name}
        </div>
    );
};
