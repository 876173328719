import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface ReportButtonProps {
    onReport: (bug: string) => void;
}

export class ReportButton extends React.Component<ReportButtonProps> {
    constructor(props: ReportButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event: React.MouseEvent<HTMLDivElement>) {
        const bug = prompt('Bug Report: What happened?');
        if (bug !== null && bug !== '') {
            this.props.onReport(bug);
        }
    }

    render() {
        return (
            <div className={'report-button border-button'} title={'Bug Report'} onClick={this.onClick}>
                <FontAwesomeIcon icon={faBug} />
            </div>
        );
    }
}
