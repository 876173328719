import * as React from 'react';

import { CreatureData } from '../interfaces';
import { CreatureInfoStat } from './CreatureInfoStat';

export interface CreatureInfoStatsProps { data: CreatureData }

export const CreatureInfoStats = (props: CreatureInfoStatsProps) => {
    return (
        <div className={'creature-info-stats'}>
            <CreatureInfoStat stat={'courage'} value={props.data ? props.data.courage : null} />
            <CreatureInfoStat stat={'power'} value={props.data ? props.data.power : null} />
            <CreatureInfoStat stat={'wisdom'} value={props.data ? props.data.wisdom : null} />
            <CreatureInfoStat stat={'speed'} value={props.data ? props.data.speed : null} />
        </div>
    );
};
