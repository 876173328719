import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';

import { GameSummaryData } from '../interfaces';
import { UserBadge } from './UserBadge';

export interface ActiveGamesProps { games: GameSummaryData[] }

const GameLink = (props: { game: GameSummaryData }) => {
    const { game } = props;

    if (game.tournament) {
        const onClick = () => {
            if (window.confirm('Do you want to spectate this tournament match?')) {
                window.location.href = `/spectate/${game.key}`;
            }
        }

        return (
            <span className={'active-game'} title="Spectate Tournament Game" onClick={onClick}>
                <UserBadge user={game.p1} />
                {game.tournament && <FontAwesomeIcon icon={faTrophy} />}
                <UserBadge user={game.p2} right />
            </span>
        );
    } else {
        return (
            <a className={'active-game'} href={`/spectate/${game.key}`} title="Spectate Game">
                <UserBadge user={game.p1} />
                <UserBadge user={game.p2} right />
            </a>
        );
    }
}

export const ActiveGames = (props: ActiveGamesProps) => {
    return (
        <div className={'active-games'}>
            <div className={'title'}>Active Games</div>
            <div className={'games'}>
                {props.games.map((game) => (
                    <GameLink key={game.key} game={game} />
                ))}
            </div>
        </div>
    );
};
