import * as React from 'react';

import { getSessionID, loggedIn } from '../helpers/local-storage';
import { DeckData, MatchData } from '../interfaces';
import { DeckPreview } from './DeckPreview';
import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { LoginRedirect } from './LoginRedirect';
import { Match } from './Match';

export interface MatchHistoryProps { socket: WebSocket }

export interface MatchHistoryState {
    message: string;
    matches: MatchData[];
    deckName: string;
    deckstring: string;
    deck: DeckData;
}

export class MatchHistory extends React.Component<MatchHistoryProps, MatchHistoryState> {
    constructor(props: MatchHistoryProps) {
        super(props);

        this.state = {
            message: null,
            matches: [],
            deckName: null,
            deckstring: null,
            deck: null,
        };

        this.props.socket.onmessage = this.onMessage.bind(this);

        this.onDeck = this.onDeck.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        this.setState({ message: 'Fetching Match History' }, () => this.getMatchHistory());
    }

    getMatchHistory() {
        const sessionID = getSessionID();

        this.props.socket.send(`matchhistory|${sessionID}`);
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        this.setState({
            message: data.message || null,
            matches: data.matches || this.state.matches || null,
            deck: data.deck || this.state.deck,
        });
    }

    onDeck(deckName: string, deckstring: string) {
        this.setState({ deckName, deckstring }, () => {
            const sessionID = getSessionID();
            this.props.socket.send(`deckstring|${sessionID}|${deckstring}`);
        });
    }

    onBack() {
        this.setState({ deckName: null, deckstring: null, deck: null });
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        if (this.state.message !== null) {
            return <FullScreenMessage message={this.state.message} />;
        }

        let content: JSX.Element = null;
        if (this.state.deck !== null) {
            content = (
                <div className={'deck'}>
                    <div className={'deck-info-row'}>
                        <div className={'button'} onClick={this.onBack}>
                            <div>Back</div>
                        </div>
                        <div className={'deck-name'}>{this.state.deckName}</div>
                        <a className={'button'} href={`data:text/plain;charset=utf-8,${this.state.deckstring}`}
                            download={`${this.state.deckName}.txt`}
                            onClick={event => event.stopPropagation()}>
                            <div>Download</div>
                        </a>
                    </div>
                    <DeckPreview deck={this.state.deck} />
                </div >
            );
        } else if (this.state.matches.length > 0) {
            content = (
                <div className={'matches'}>
                    {this.state.matches.map((match, index) => (
                        <Match key={index} data={match} onDeck={this.onDeck} />
                    ))}
                </div>
            );
        } else {
            content = (
                <div className={'no-matches-message'}>
                    <div>You have no matches played</div>
                </div>
            );
        }

        return (
            <div className={'match-history'}>
                <div className={'title-row'}>
                    <HomeButton />
                    <div className={'title'}>Match History</div>
                </div>
                {content}
            </div>
        );
    }
}
