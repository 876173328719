import * as React from 'react';

import { LocationData, MouseOverData, OnSelect } from '../interfaces';
import { LocationCard } from './LocationCard';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { Selectable } from './Selectable';

export interface ActiveLocationProps {
    action: string;
    onSelect: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    data: LocationData;
}

export const ActiveLocation = (props: ActiveLocationProps) => {
    const selectable = props.action === 'priority'
        && props.data.activatedAbilities.some(ability => !ability.restricted && ability.canPayCost);

    return (
        <div className={'active-location'}>
            <Selectable
                id={0}
                selectable={selectable}
                onSelect={props.onSelect}
            >
                <MouseMoveCapturer
                    data={props.data}
                    imageURL={props.data.imageURL}
                    onMouseOverCard={props.onMouseOverCard}
                    type={'location'}>
                    <LocationCard data={props.data} />
                </MouseMoveCapturer >
            </Selectable>
        </div >
    );
};
