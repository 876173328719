import * as React from 'react';

import { LocationData, MouseOverData, OnSelect } from '../interfaces';
import { CardImage } from './CardImage';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { Selectable } from './Selectable';
import { TargetIcon } from './TargetIcon';

export interface MirageLocationProps {
    id: number;
    onSelectMirage: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    data: LocationData;
    selectable: boolean;
    targeted: boolean;
    showing: string[];
}

export const MirageLocation = (props: MirageLocationProps) => {
    const locationClassName = 'location' + (props.data && props.data.owner ? ' owner' : '');
    return (
        <div className={'mirage-location'}>
            <Selectable
                id={props.id}
                selectable={props.selectable}
                onSelect={props.onSelectMirage}
                selected={false}
                type={'mirage-location'}
                showing={props.showing.some(showing => showing === 'mirage-location')}>
                <MouseMoveCapturer
                    imageURL={props.data && props.data.imageURL}
                    onMouseOverCard={props.onMouseOverCard}
                    data={props.data}
                    type={'mirage-location'}>
                    <div className={locationClassName}>
                        <CardImage name={props.data.name} imageURL={props.data.imageURL} />
                        {props.targeted && <TargetIcon />}
                    </div>
                </MouseMoveCapturer>
            </Selectable>
        </div>
    );
};
