import * as React from 'react';

import { tribeColor } from '../helpers/tribe-color';
import { MugicCounter } from './MugicCounter';

export interface MugicCountersProps { owner: boolean; tribes: string[]; numCounters: number }

export const MugicCounters = (props: MugicCountersProps) => {
    let counters: JSX.Element | JSX.Element[];
    if (props.numCounters >= 5) {
        counters = <div style={{ color: tribeColor(props.tribes[0]) }}>{props.numCounters}</div>;
    } else if (props.numCounters > 0) {
        counters = [];
        for (let i = 0; i < props.numCounters; i++) {
            counters.push(<MugicCounter key={i} size={10} tribes={props.tribes} fill owner={props.owner} />);
        }
    }

    return (
        <div className={'mugic-counters'}>
            {counters}
        </div>
    );
};
