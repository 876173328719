import * as React from 'react';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';

import { DISCORD_REDIRECT_URI, HOST, NODE_ENV, PORT } from '../App';
import { deleteSessionID, getOAuthState, getSessionID, setSessionID } from '../helpers/local-storage';
import { Banlists } from './Banlists';
import { CreateGame } from './CreateGame';
import { DeckBuilder } from './DeckBuilder';
import { FullScreenMessage } from './FullScreenMessage';
import { Game } from './Game';
import { Home } from './Home';
import { Login } from './Login';
import { MatchHistory } from './MatchHistory';
import { Matchmaking } from './Matchmaking';
import { SharedDeck } from './SharedDeck';
import { SocketProvider } from './SocketContext';

const MAX_RECONNECT_ATTEMPTS = 10;

export interface SocketWrapperState {
    socket: WebSocket;
    reconnectAttempts: number;
    sessionID: string;
    banned: boolean;
}

export class SocketWrapper extends React.Component<{}, SocketWrapperState> {
    constructor(props: {}) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.reopenSocket = this.reopenSocket.bind(this);

        const socket = this.openSocket();

        this.state = { socket, reconnectAttempts: 0, sessionID: getSessionID(), banned: false };
    }

    openSocket() {
        const socketURL = NODE_ENV === 'production' ? `wss://${HOST}` : `ws://${HOST}:${PORT}`;
        const socket = new WebSocket(socketURL);
        socket.onclose = this.onClose;
        socket.onerror = () => this.forceUpdate();
        socket.onopen = this.onOpen;
        socket.onmessage = this.onMessage;
        return socket;
    }

    onClose() {
        if (this.state.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
            this.setState({ reconnectAttempts: this.state.reconnectAttempts + 1 }, () => {
                window.setTimeout(() => this.reopenSocket(), this.state.reconnectAttempts * 1000);
            });
        } else {
            this.forceUpdate();
        }
    }

    onOpen() {
        window.onbeforeunload = () => this.state.socket.close();
        const sessionID = getSessionID();
        if (sessionID === null) {
            const urlParams = new URLSearchParams(window.location.search);
            const accessCode = urlParams.get('code');
            if (accessCode !== null) {
                const oauthState = getOAuthState();
                const stateParam = urlParams.get('state');
                if (oauthState === stateParam) {
                    const redirectURI = DISCORD_REDIRECT_URI;
                    this.state.socket.send(`login|${accessCode}|${redirectURI}`);
                } else {
                    console.error('Invalid state param');
                }
            }
        }
        this.setState({ reconnectAttempts: 0 });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        if (data.logout) {
            deleteSessionID();
            this.setState({ sessionID: null });
        }
        if (data.sessionID) {
            setSessionID(data.sessionID);
            this.setState({ sessionID: data.sessionID });
        }
        if (data.banned !== undefined) {
            this.setState({ banned: data.banned });
        }
    }

    reopenSocket() {
        const socket = this.openSocket();
        this.setState({ socket });
    }

    render() {
        if (this.state.socket === null || this.state.socket.readyState === WebSocket.CONNECTING) {
            return <FullScreenMessage message={'Connecting to Server'} />;
        } else if (this.state.socket.readyState === WebSocket.CLOSING
            || this.state.socket.readyState === WebSocket.CLOSED) {
            if (this.state.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
                return <FullScreenMessage message={`Connection to Server Closed. Reconnecting (${this.state.reconnectAttempts} Attempts)`} />;
            } else {
                return <FullScreenMessage message={'Connection to Server Closed'} />;
            }
        }

        if (this.state.banned) {
            return <FullScreenMessage message={'You are banned. Please message Chaotic Mod Mail in the Chaotic Discord community to understand why you are banned, or to appeal this ban.'} />;
        }

        return (
            <SocketProvider value={this.state.socket}>
                <BrowserRouter>
                    <Route path={'/login'} exact render={(routeProps: RouteComponentProps) => <Login />} />
                    <Route path={'/'} exact render={(routeProps: RouteComponentProps) => <Home socket={this.state.socket} />} />
                    <Route path={'/:game([A-Fa-f0-9]{32})'} render={(routeProps: RouteComponentProps) => <Game {...this.state} reopenSocket={this.reopenSocket} {...routeProps} />} />
                    <Route path={'/spectate/:game([A-Fa-f0-9]{32})'} render={(routeProps: RouteComponentProps) => <Game {...this.state} reopenSocket={this.reopenSocket} {...routeProps} spectate />} />
                    <Route path={'/matchmaking'} exact render={(routeProps: RouteComponentProps) => <Matchmaking socket={this.state.socket} />} />
                    <Route path={'/creategame'} exact render={(routeProps: RouteComponentProps) => <CreateGame socket={this.state.socket} {...routeProps} />} />
                    <Route path={'/deck/:deck([A-Fa-f0-9]{32})'} exact render={(routeProps: RouteComponentProps) => <SharedDeck socket={this.state.socket} {...routeProps} />} />
                    <Route path={'/deckbuilder'} exact render={(routeProps: RouteComponentProps) => <DeckBuilder socket={this.state.socket} />} />
                    <Route path={'/matchhistory'} exact render={(routeProps: RouteComponentProps) => <MatchHistory socket={this.state.socket} />} />
                    <Route path={'/banlists'} exact render={(routeProps: RouteComponentProps) => <Banlists socket={this.state.socket} />} />
                </BrowserRouter>
            </SocketProvider>
        );
    }
}
