import * as React from 'react';

import { CreatureData } from '../interfaces';
import { BugIcon } from './BugIcon';
import { CardImage } from './CardImage';
import { TargetIcon } from './TargetIcon';

export interface CreatureProps { data: CreatureData; targeted: boolean }

export const Creature = (props: CreatureProps) => {
    const className = 'creature' + (props.data && props.data.name ? ' filled' : '') + (props.data && props.data.owner ? ' owner' : '');

    return (
        <div className={className}>
            <CardImage name={props.data && props.data.name} imageURL={props.data && props.data.imageURL} />
            {props.targeted && <TargetIcon />}
            {props.data && props.data.infected && <BugIcon tooltip={'Infected'} />}
        </div>
    );
};

Creature.defaultProps = {
    targeted: false,
};
