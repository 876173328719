import * as React from 'react';

import { OnSelect } from '../interfaces';

export interface SelectableProps {
    id: number;
    showSelectable: boolean; // Override to show that a space is selectable when it is not
    selectable: boolean;
    onSelect: OnSelect;
    selected: boolean;
    highlighted: boolean;
    type: string;
    showing: boolean;
}

export class Selectable extends React.Component<SelectableProps> {
    static defaultProps = {
        showSelectable: false,
        selected: false,
        highlighted: false,
        type: null,
        showing: false,
    }

    constructor(props: SelectableProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.selectable) {
            this.props.onSelect(this.props.id);
        }
    }

    render() {
        let className = 'selectable' +
            (this.props.selected ? ' selected' :
                ((this.props.selectable || this.props.showSelectable) ? ' clickable' : '')); // Override to show that a space is selectable when it is not
        if (this.props.highlighted) {
            className += ' highlighted';
        }
        if (this.props.showing) {
            className += ' selectable-showing';
        } else if (this.props.type === 'creature') { // Doesn't apply other selectors if showing to avoid conflicts
            className += ' selectable-creature';
        } else if (this.props.type === 'battlegear') {
            className += ' selectable-battlegear';
        }

        return (
            <div className={className} onClick={this.onClick} >
                {this.props.children}
            </div>
        );
    }
}
