import * as React from 'react';

import { CardData } from '../interfaces';
import { CardImage } from './CardImage';
import { TargetIcon } from './TargetIcon';

export interface CardProps { data: CardData; targeted: boolean }

export const Card = (props: CardProps) => {
    return (
        <div className={'card'}>
            <CardImage name={props.data.name} imageURL={props.data.imageURL} />
            {props.targeted && <TargetIcon />}
        </div>
    );
};

Card.defaultProps = {
    targeted: false,
};
