import * as React from 'react';

import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';

export interface BanlistsProps { socket: WebSocket }
export interface BanlistsState {
    message: string;
    cardsExcluded: string[];
    banlists: Record<string, string[]>;
    uniquelists: Record<string, string[]>;
}

export class Banlists extends React.Component<BanlistsProps, BanlistsState> {
    constructor(props: BanlistsProps) {
        super(props);

        this.state = {
            message: 'Connecting to Server',
            cardsExcluded: null,
            banlists: null,
            uniquelists: null,
        };

        this.props.socket.onmessage = this.onMessage.bind(this);
    }

    componentDidMount() {
        this.setState({ message: 'Fetching Banlists' }, () => {
            this.props.socket.send('banlists');
        });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        this.setState({
            message: null,
            banlists: data.banlists || null,
            uniquelists: data.uniquelists || null,
        });
    }

    render() {
        if (this.state.message) {
            return <FullScreenMessage message={this.state.message} />;
        }

        let title = 'Banlists';
        if ('Standard (Whacky Weekend)' in this.state.banlists) {
            title = 'Banlists (Whacky Weekend)';
        }

        return (
            <div className={'banlists'}>
                <div className={'title-row'}>
                    <HomeButton />
                    <div className={'title'}>{title}</div>
                </div>
                <div className={'columns'}>
                    <div className={'column'}>
                        <div className={'title'}>Banned Cards</div>
                        <div className={'line'} />
                        <div className={'content'}>
                            {Object.entries(this.state.banlists).map(([format, cards], index) => (
                                <div className={'content-container'} key={index}>
                                    <div className={'title'}>{format}</div>
                                    <div className={'line'} />
                                    {cards.length > 0
                                        ? cards.map((card, index) => <div key={index}>{card}</div>)
                                        : <div>No cards banned</div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={'column'}>
                        <div className={'title'}>Unique Cards</div>
                        <div className={'line'} />
                        <div className={'content'}>
                            {Object.entries(this.state.uniquelists).map(([format, cards], index) => (
                                <div className={'content-container'} key={index}>
                                    <div className={'title'}>{format}</div>
                                    <div className={'line'} />
                                    {cards.length > 0
                                        ? cards.map((card, index) => <div key={index}>{card}</div>)
                                        : <div>No cards unique</div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
