import * as React from 'react';
import { UserData } from '../interfaces';
import { ScrollList } from './ScrollList';

export interface ChatProps {
    chatMessages: string[];
    spectate: boolean;
    onSubmitChat: (message: string) => void;
    ownUser: UserData;
    opponentUser: UserData;
}

export interface ChatState {
    message: string;
}

export class Chat extends React.Component<ChatProps, ChatState> {
    constructor(props: ChatProps) {
        super(props);

        this.state = {
            message: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ message: event.currentTarget.value });
    }

    onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (event.key === 'Enter'
            && this.state.message.length > 0) {
            event.preventDefault();
            this.props.onSubmitChat(this.state.message);
            this.setState({ message: '' });
        }
    }

    getContent() {
        const ownBan = this.props.ownUser.chatBan;
        const oppBan = this.props.opponentUser.chatBan;
        const spectator = this.props.spectate;

        if (!spectator) {
            if (ownBan) {
                return <div className={'chat-ban'}>You have been chat restricted.</div>;
            }
            else if (oppBan) {
                return <div className={'chat-ban'}>Your opponent has been chat restricted.</div>;
            }
            else if (!ownBan && !oppBan) {
                return <input
                    className={'chat-input'}
                    type={'text'}
                    value={this.state.message}
                    placeholder={'Type a Message...'}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown} />;
            }
        }
        return null;
    }

    render() {
        const logs = this.props.chatMessages.slice();
        return (
            <div className={'chat'}>
                <ScrollList logs={logs} className='messages' />
                {this.getContent()}
            </div>
        );
    }
}
