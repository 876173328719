import * as React from 'react';

import { GamePosition, GeneralDiscardData, OnSelect } from '../interfaces';
import { Choice } from './Choice';
import { Selectable } from './Selectable';

export interface TargetsProps {
    type: string;
    selected: GamePosition[];
    optional: boolean;
    p1: boolean;
    onSelectTarget: (type: string) => OnSelect;
    mouseOverTargets: GamePosition[];
    data: GeneralDiscardData;
}

// Used to display players or cards in general discard so that they can be targeted
export const Targets = (props: TargetsProps) => {
    const targetingCreature = props.type === 'Creature' || props.type === 'Card';
    const targetedCreatures = props.mouseOverTargets
        .filter(target => target.type === 'discard-creature')
        .map(target => target.id);

    const targetingBattlegear = props.type === 'Battlegear' || props.type === 'Card';
    const targetedBattlegear = props.mouseOverTargets
        .filter(target => target.type === 'discard-battlegear')
        .map(target => target.id);

    const targetingMugic = props.type === 'Mugic' || props.type === 'Card';
    const targetedMugic = props.mouseOverTargets
        .filter(target => target.type === 'discard-mugic')
        .map(target => target.id);

    const targetingPlayer = props.type === 'Player';
    const targetedPlayer = props.mouseOverTargets
        .filter(target => target.type === 'player')
        .map(target => target.id);

    return (
        <div className={'targets'}>
            {(targetingCreature || targetedCreatures.length > 0)
                && props.data.creatures.map((target, index) => {
                    const type = 'discard-creature';
                    const id = target.id;
                    const targeted = targetedCreatures.includes(id);
                    const selected = props.selected
                        .some(position => position.type === type && position.id === id);

                    if (targetedCreatures.length > 0 && !targeted) {
                        return null;
                    }

                    return (
                        <Selectable
                            key={index}
                            id={id}
                            selectable={targetingCreature}
                            onSelect={props.onSelectTarget(type)}
                            selected={selected}>
                            <Choice data={{ type, value: target }} targeted={targeted} />
                        </Selectable>
                    );
                })}
            {(targetingBattlegear || targetedBattlegear.length > 0)
                && props.data.battlegear.map((target, index) => {
                    const type = 'discard-battlegear';
                    const id = target.id;
                    const targeted = targetedBattlegear.includes(id);
                    const selected = props.selected
                        .some(position => position.type === type && position.id === id);

                    if (targetedBattlegear.length > 0 && !targeted) {
                        return null;
                    }

                    return (
                        <Selectable
                            key={index}
                            id={id}
                            selectable={targetingBattlegear}
                            onSelect={props.onSelectTarget(type)}
                            selected={selected}>
                            <Choice data={{ type, value: target }} targeted={targeted} />
                        </Selectable>
                    );
                })}
            {(targetingMugic || targetedMugic.length > 0)
                && props.data.mugic.map((target, index) => {
                    const type = 'discard-mugic';
                    const id = target.id;
                    const targeted = targetedMugic.includes(id);
                    const selected = props.selected.some(position =>
                        position.type === type && position.id === id);

                    if (targetedMugic.length > 0 && !targeted) {
                        return null;
                    }

                    return (
                        <Selectable
                            key={index}
                            id={id}
                            selectable={targetingMugic}
                            onSelect={props.onSelectTarget(type)}
                            selected={selected}>
                            <Choice data={{ type, value: target }} targeted={targeted} />
                        </Selectable>
                    );
                })}
            {(targetingPlayer || targetedPlayer.length > 0)
                && (props.p1 ? ['You', 'Opponent'] : ['Opponent', 'You']).map((target, index) => {
                    const targeted = targetedPlayer.includes(index);
                    const type = 'player';

                    return (
                        <Selectable
                            key={index}
                            id={index}
                            selectable={targetingPlayer}
                            onSelect={props.onSelectTarget(type)}>
                            <Choice data={{ type, value: target }} targeted={targeted} />
                        </Selectable>
                    );
                })}
            {props.optional && (
                <Selectable
                    id={0}
                    selectable={true}
                    onSelect={props.onSelectTarget('submit')}>
                    <Choice data={{ type: 'string', value: 'Submit' }} targeted={false} />
                </Selectable>
            )}
        </div>
    );
};
