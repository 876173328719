import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { getSessionID, loggedIn } from '../helpers/local-storage';
import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { LoginRedirect } from './LoginRedirect';
import { CustomSettings, GameSettings, UpdateSettingsHandle } from './CustomSettings';

export interface CreateGameProps { socket: WebSocket }
export interface CreateGameState {
    message: string;
    link: string;
    key: string;
    admin: boolean;
    debug: string;
    gameSettings: GameSettings;
}

export class CreateGame extends React.Component<CreateGameProps & RouteComponentProps, CreateGameState> {
    settingsRef: React.RefObject<UpdateSettingsHandle>;

    constructor(props: CreateGameProps & RouteComponentProps) {
        super(props);

        this.settingsRef = React.createRef<UpdateSettingsHandle>();

        this.state = {
            message: null,
            link: null,
            key: null,
            admin: false,
            debug: '',
            gameSettings: {},
        };

        this.props.socket.onmessage = this.onMessage.bind(this);
        this.onChangeDebug = this.onChangeDebug.bind(this);
        this.onSubmitDebug = this.onSubmitDebug.bind(this);
    }

    componentDidMount() {
        this.setState({ message: 'Creating Game' }, () => {
            const sessionID = getSessionID();
            this.props.socket.send(`create|${sessionID}`);
        });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        if (data.updated) {
            this.props.history.push(this.state.link.substring(this.state.link.lastIndexOf('/')));
            return;
        }
        const newState = {
            message: data.error || data.message || this.state.message,
            link: data.link || this.state.link,
            key: data.key || this.state.key,
            admin: data.admin || this.state.admin,
            gameSettings: data.gameSettings || this.state.gameSettings,
        };
        this.setState(newState);
    }

    onChangeDebug(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ debug: event.target.value });
    }

    onSubmitDebug() {
        this.setState({ message: 'Submitting Debug' }, () => {
            const sessionID = getSessionID();
            this.props.socket.send(`debug|${sessionID}|${this.state.key}|${this.state.debug}`);
        });
    }

    createGame() {
        this.settingsRef.current.updateGameSettings();
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        const url = this.state.link && this.state.link.substring(this.state.link.lastIndexOf('/'));
        const text = window.location.href.substring(0, window.location.href.lastIndexOf('/')) + url;
        const debug = this.state.key && this.state.admin;
        const message = this.state.message !== 'Game Created' ? this.state.message : '';

        return (
            <div className={'create-game'}>
                <FullScreenMessage message={message}>
                    {debug && (
                        <div className={'debug-wrapper'}>
                            <textarea
                                className={'debug-text-area'}
                                onChange={this.onChangeDebug}
                                placeholder={'Debug'}></textarea>
                            <div className={'debug-button'} onClick={this.onSubmitDebug}>
                                <div>Submit</div>
                            </div>
                        </div>
                    )}
                    {url && (
                        <div className='create-button'>
                            <a role="button" onClick={() => this.createGame()}>Create Game</a>
                        </div>
                    )}
                    {url && (
                        <CustomSettings
                            ref={this.settingsRef}
                            gameSettings={this.state.gameSettings}
                            gameKey={url.slice(1)}
                            create
                        />
                    )}
                </FullScreenMessage>
                <HomeButton />
            </div>
        );
    }
}
