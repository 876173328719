import * as React from 'react';

import { AttackData, MouseOverData, OnSelect } from '../interfaces';
import { Card } from './Card';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { Selectable } from './Selectable';

export interface AttackHandProps {
    action: string;
    onSelect: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    data: AttackData[];
}

export const AttackHand = (props: AttackHandProps) => {
    return (
        <div className={'attack-hand'}>
            {props.data.map((attack, index) =>
                <Selectable
                    key={index}
                    id={index}
                    selectable={props.action === 'strike'}
                    onSelect={props.onSelect}>
                    <MouseMoveCapturer
                        data={attack}
                        imageURL={attack.imageURL}
                        onMouseOverCard={props.onMouseOverCard}
                        type={'attack'}>
                        <Card data={attack} />
                    </MouseMoveCapturer>
                </Selectable>)}
        </div>
    );
};
