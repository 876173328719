import * as React from 'react';

import * as courage from '../assets/courage.png';
import * as power from '../assets/power.png';
import * as speed from '../assets/speed.png';
import * as wisdom from '../assets/wisdom.png';

export interface CreatureInfoStatProps { stat: string; value: number }

export const CreatureInfoStat = (props: CreatureInfoStatProps) => {
    let image: string;
    switch (props.stat) {
        case 'courage':
            image = courage;
            break;
        case 'power':
            image = power;
            break;
        case 'wisdom':
            image = wisdom;
            break;
        case 'speed':
            image = speed;
            break;
    }

    return (
        <div className={'creature-info-stat'}>
            <div className={'creature-info-stat-image'} style={{ backgroundImage: `url(/${image})` }} />
            <div className={'creature-info-stat-data'}>
                {props.value}
            </div>
        </div>
    );
};
