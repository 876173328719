import * as React from 'react';

import { BattlegearData, CreatureData, MouseOverData, OnSelect } from '../interfaces';
import { Battlegear } from './Battlegear';
import { Creature } from './Creature';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { MugicCounters } from './MugicCounters';
import { Selectable } from './Selectable';

export interface CreatureAreaProps {
    onSelectCreature: OnSelect;
    onSelectBattlegear: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    owner: boolean;
    tribes: string[];
    mugicCounters: number;
    creature: CreatureData;
    creatureSelectable: boolean;
    creatureSelected: boolean;
    creatureHighlighted: boolean;
    creatureTargeted: boolean;
    battlegear: BattlegearData;
    battlegearSelectable: boolean;
    battlegearSelected: boolean;
    battlegearTargeted: boolean;
    showing: string;
}

export const CreatureArea = (props: CreatureAreaProps) => {
    return (
        <div className={'creature-area'}>
            <MugicCounters owner={props.owner} tribes={props.tribes} numCounters={props.mugicCounters} />
            <Selectable
                id={props.creature && props.creature.id}
                selectable={props.creatureSelectable}
                onSelect={props.onSelectCreature}
                selected={props.creatureSelected}
                highlighted={props.creatureHighlighted}
                type={'creature'}
                showing={props.showing === 'creature'}>
                <MouseMoveCapturer
                    imageURL={props.creature && props.creature.imageURL}
                    onMouseOverCard={props.onMouseOverCard}
                    data={props.creature}
                    type={'creature'}>
                    <Creature data={props.creature} targeted={props.creatureTargeted} />
                </MouseMoveCapturer>
            </Selectable>
            <Selectable
                id={props.battlegear && props.battlegear.id}
                selectable={props.battlegearSelectable}
                onSelect={props.onSelectBattlegear}
                selected={props.battlegearSelected}
                type={'battlegear'}
                showing={props.showing === 'battlegear'}>
                <MouseMoveCapturer
                    imageURL={props.battlegear && props.battlegear.imageURL}
                    onMouseOverCard={props.onMouseOverCard}
                    data={props.battlegear}
                    type={'battlegear'}>
                    <Battlegear data={props.battlegear} targeted={props.battlegearTargeted} />
                </MouseMoveCapturer>
            </Selectable>
        </div>
    );
};

CreatureArea.defaultProps = {
    onSelectCreature: (id: number) => {},
    onSelectBattlegear: (id: number) => {},
    onMouseOverCard: (data: MouseOverData) => {},
    owner: null,
    tribes: [],
    mugicCounters: null,
    creature: null,
    creatureSelectable: null,
    creatureSelected: null,
    creatureHighlighted: null,
    creatureTargeted: null,
    battlegear: null,
    battlegearSelectable: null,
    battlegearSelected: null,
    battlegearTargeted: null,
    showing: null,
};
