import * as React from 'react';
import { Link, Prompt, Redirect, RouteComponentProps } from 'react-router-dom';

import {
    getSessionID, loggedIn, getAcceptedRotation, setAcceptedRotation, deleteAcceptedRotation
} from '../helpers/local-storage';
import { requestNotificationsPermission, sendNotification } from '../helpers/notification';
import { DeckMetaDataData, GameSummaryData } from '../interfaces';
import { DeckPicker } from './DeckPicker';
import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { LoginRedirect } from './LoginRedirect';
import Logo from './Logo';
import { ActiveGames } from './ActiveGames';
import { isRotationDay } from '../helpers/rotation';

const SECOND = 1000;
const MATCH_FOUND_TIMEOUT_SECS = 15;

export enum Queue {
    STANDARD_UNRANKED = 'standard_unranked',
    UNRESTRICTED_UNRANKED = 'unrestricted_unranked',
    ADVANCED_APPRENTICE_UNRANKED = 'advanced_apprentice_unranked',
    PAUPER_UNRANKED = 'pauper_unranked',
    STANDARD_RANKED = 'standard_ranked',
}

export interface MatchmakingProps { socket: WebSocket }
export interface MatchmakingState {
    message: string;
    error: string;
    deckMetaDatas: DeckMetaDataData[];
    games: GameSummaryData[];
    queue: Queue;
    queueing: boolean;
    numDots: number;
    matchFound: boolean;
    secondsLeft: number;
    key: string;
    rotation: {
        banlist: string[];
        uniquelist: string[];
    };
    disconnected: string;
}

export class Matchmaking extends React.Component<MatchmakingProps, MatchmakingState> {
    dotsInterval: number;
    matchFoundTimeout: number;

    constructor(props: MatchmakingProps) {
        super(props);

        this.state = {
            message: 'Fetching Matchmaking',
            error: null,
            deckMetaDatas: null,
            games: null,
            queue: null,
            queueing: false,
            numDots: 1,
            matchFound: false,
            secondsLeft: null,
            key: null,
            rotation: null,
            disconnected: null,
        };

        this.dotsInterval = null;
        this.matchFoundTimeout = null;

        this.props.socket.onmessage = this.onMessage.bind(this);
    }

    componentDidMount() {
        const sessionID = getSessionID();
        this.props.socket.send(`matchmaking|${sessionID}`);

        window.onbeforeunload = () => this.cleanup();
        requestNotificationsPermission();

        // gonna make an assumption that the user logs in other days then rotation day, and reset the warning for next week
        if (!isRotationDay()) {
            deleteAcceptedRotation();
        }

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        this.cleanup();
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event: BeforeUnloadEvent) => {
        this.cleanup();
    };

    cleanup() {
        if (this.state.queueing) {
            this.unqueue();
        }
        if (this.state.matchFound) {
            this.declineMatch();
        }
        if (this.dotsInterval !== null) {
            this.clearInterval();
        }
        if (this.matchFoundTimeout !== null) {
            this.clearTimeout();
        }
    }

    clearInterval() {
        window.clearInterval(this.dotsInterval);
        this.dotsInterval = null;
    }

    setTimeout(secs: number) {
        if (secs === 0) {
            this.clearTimeout();
            this.declineMatch();
        } else {
            this.setState({ secondsLeft: secs }, () => {
                this.matchFoundTimeout = window.setTimeout(() => this.setTimeout(secs - 1), SECOND);
            });
        }
    }

    clearTimeout() {
        window.clearTimeout(this.matchFoundTimeout);
        this.matchFoundTimeout = null;
        this.setState({ secondsLeft: null });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        let newState = {
            message: data.message || null,
            error: data.error || this.state.error || null,
            deckMetaDatas: data.deckMetaDatas || this.state.deckMetaDatas,
            queue: this.state.queue,
            queueing: this.state.queueing,
            matchFound: this.state.matchFound,
            key: data.key || this.state.key,
            games: data.games || this.state.games,
            rotation: data.rotation || this.state.rotation,
            disconnected: data.disconnected || this.state.disconnected,
        };

        if (!newState.error && newState.deckMetaDatas) {
            if (!isRotationDay() || getAcceptedRotation()) {
                newState = { ...newState, rotation: { banlist: [], uniquelist: [] } };
            }
            if (!newState.rotation) {
                this.props.socket.send('rotation');
                newState = { ...newState, message: 'Fetching Matchmaking' }; // no matchmaking until rotation data is loaded
            }
            else if (!newState.games) {
                // get active games
                this.props.socket.send('games');
            }
        }
        if (!newState.deckMetaDatas && !data.message) {
            newState = { ...newState, message: 'Fetching Matchmaking' };
        }
        if (data.queueing !== undefined) { // Must check undefined because queueing is boolean
            newState = { ...newState, queueing: data.queueing };
            if (data.queueing) {
                this.dotsInterval = window.setInterval(() => this.setState({ numDots: this.state.numDots % 3 + 1 }), SECOND);
            } else {
                this.clearInterval();
            }
        }
        if (data.matchFound !== undefined) { // Must check undefined because matchFound is boolean
            newState = { ...newState, matchFound: data.matchFound };
            if (data.matchFound) {
                sendNotification('Match Found!');
                this.setTimeout(MATCH_FOUND_TIMEOUT_SECS);
            } else {
                this.clearTimeout();
            }
        }
        this.setState(newState);
    }

    findMatch(queue: Queue) {
        this.setState({ queue });
    }

    queue(deckID: number) {
        const sessionID = getSessionID();
        const queue = this.state.queue;
        this.props.socket.send(`queue|${sessionID}|${queue}|${deckID}`);
    }

    unqueue() {
        const sessionID = getSessionID();
        const queue = this.state.queue;
        this.props.socket.send(`unqueue|${sessionID}|${queue}`);
        this.setState({ queue: null });
    }

    acceptMatch() {
        this.clearTimeout();
        const sessionID = getSessionID();
        this.props.socket.send(`acceptmatch|${sessionID}`);
    }

    declineMatch() {
        const sessionID = getSessionID();
        this.props.socket.send(`declinematch|${sessionID}`);
        this.setState({ queue: null });
    }

    getQueueDisplayName() {
        switch (this.state.queue) {
            case Queue.STANDARD_UNRANKED:
                return 'Standard';
            case Queue.UNRESTRICTED_UNRANKED:
                return 'Unrestricted Unranked';
            case Queue.ADVANCED_APPRENTICE_UNRANKED:
                return 'Advanced Apprentice Unranked';
            case Queue.PAUPER_UNRANKED:
                return 'Pauper Unranked';
            case Queue.STANDARD_RANKED:
                return 'Standard Ranked';
        }
    }

    getFormat() {
        switch (this.state.queue) {
            case Queue.STANDARD_UNRANKED:
            case Queue.STANDARD_RANKED:
                return 'standard';
            case Queue.UNRESTRICTED_UNRANKED:
                return 'unrestricted';
            case Queue.ADVANCED_APPRENTICE_UNRANKED:
                return 'advanced_apprentice';
            case Queue.PAUPER_UNRANKED:
                return 'pauper';
        }
    }

    findStandardMatch() {
        // this will only get set if user hasn't accepted rotation
        if (this.state.rotation?.banlist?.length > 0) {
            let message = 'It\'s a Whacky Weekend! The following changes are in effect during this weekend. Click OK to continue to standard queue.\nBans:';
            for (const card of [...this.state.rotation.banlist, 'Uniques:', ...this.state.rotation.uniquelist]) {
                if (message.length + card.length + 4 > 999) {
                    message += '\n...';
                    break;
                } else {
                    message += `\n${card}`;
                }
            }

            if (window.confirm(message)) {
                setAcceptedRotation();
            } else {
                return;
            }
        }

        this.findMatch(Queue.STANDARD_UNRANKED);
    }

    getContent() {
        if (this.state.matchFound) {
            return (
                <>
                    <Prompt message={'Leaving will decline your match.'} />
                    <div className={'match-found-message'}>Match Found!</div>
                    <div className={'match-found-timer'}>{this.state.secondsLeft}</div>
                    <div className={'match-found-buttons'}>
                        <div onClick={() => this.acceptMatch()}>Accept</div>
                        <div onClick={() => this.declineMatch()}>Decline</div>
                    </div>
                </>
            );
        } else if (this.state.queueing) {
            return (
                <>
                    <Prompt message={'Leaving will remove you from the queue.'} />
                    <div className={'queueing-message'}>
                        Searching for {this.getQueueDisplayName()} match {'.'.repeat(this.state.numDots)}{'\u00A0'.repeat(3 - this.state.numDots)}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {this.state.games?.length > 0 && (
                        <ActiveGames games={this.state.games} />
                    )}
                    <Logo />
                    <div className={'buttons'}>
                        {this.state.disconnected ? (
                            <Link to={`/${this.state.disconnected}`}>Reconnect</Link>
                        ) : (
                            <>
                                <div className={'find-unranked'} onClick={() => this.findStandardMatch()}>Standard</div>
                                <div className={'find-unranked'} onClick={() => this.findMatch(Queue.ADVANCED_APPRENTICE_UNRANKED)}>Advanced Apprentice</div>
                                <div className={'find-unranked'} onClick={() => this.findMatch(Queue.UNRESTRICTED_UNRANKED)}>Unrestricted</div>
                            </>
                        )}
                    </div>
                </>
            );
        }
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        if (this.state.key !== null) {
            return <Redirect to={`/${this.state.key}`} />;
        }

        if (this.state.error !== null) {
            return <FullScreenMessage message={this.state.error} />;
        }

        if (this.state.message !== null) {
            return <FullScreenMessage message={this.state.message} />;
        }

        if (this.state.queue !== null && !this.state.queueing && !this.state.matchFound) {
            return <DeckPicker decks={this.state.deckMetaDatas} format={this.getFormat()} onSelect={(deckID: number) => this.queue(deckID)} />;
        }

        const content = this.getContent();

        return (
            <div className={'matchmaking'}>
                <HomeButton />
                {content}
            </div>
        );
    }
}
