import * as React from 'react';

import { BurstElementData, GamePosition, GeneralDiscardData, MouseOverData } from '../interfaces';
import { Card } from './Card';
import { MouseMoveCapturer } from './MouseMoveCapturer';

export interface GeneralDiscardProps {
    selected: GamePosition[];
    mouseOverBurstElement: BurstElementData;
    onMouseOverCard: (data: MouseOverData) => void;
    data: GeneralDiscardData;
}

export const GeneralDiscard = (props: GeneralDiscardProps) => {
    return (
        <div className={'general-discard'}>
            <div className={'general-discard-cards general-discard-creatures'}>
                {props.data.creatures.map((creature, index) => (
                    <MouseMoveCapturer
                        key={index}
                        imageURL={creature.imageURL}
                        onMouseOverCard={props.onMouseOverCard}
                        data={creature}
                        type={'creature'}>
                        <Card data={creature} />
                    </MouseMoveCapturer>))}
            </div>
            <div className={'general-discard-cards general-discard-battlegear'}>
                {props.data.battlegear.map((battlegear, index) => (
                    <MouseMoveCapturer
                        key={index}
                        imageURL={battlegear.imageURL}
                        onMouseOverCard={props.onMouseOverCard}
                        data={battlegear}
                        type={'battlegear'}>
                        <Card data={battlegear} />
                    </MouseMoveCapturer>))}
            </div>
            <div className={'general-discard-cards general-discard-mugic'}>
                {props.data.mugic.map((mugic, index) => (
                    <MouseMoveCapturer
                        key={index}
                        imageURL={mugic.imageURL}
                        onMouseOverCard={props.onMouseOverCard}
                        data={mugic}
                        type={'mugic'}>
                        <Card data={mugic} />
                    </MouseMoveCapturer>))}
            </div>
        </div>
    );
};
