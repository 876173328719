import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React = require('react');

export const TargetIcon = () => {
    return (
        <div className={'target-icon'}>
            <FontAwesomeIcon icon={faBullseye} />
        </div>
    );
};
