import * as React from 'react';

export interface PassButtonProps {
    enabled: boolean;
    text: string;
    onPass: () => void;
}

export class PassButton extends React.Component<PassButtonProps> {
    constructor(props: PassButtonProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event: React.MouseEvent<HTMLDivElement>) {
        if (this.props.enabled) {
            this.props.onPass();
        }
    }

    render() {
        const className = 'pass-button border-button' + (this.props.enabled ? ' active' : '');
        return (
            <div className={className} onClick={this.onClick}>
                <div>{this.props.text}</div>
            </div>
        );
    }
}
