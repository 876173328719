export interface VersionData {
    version: string;
    changes: string[];
}

export interface MouseOverData {
    x: number;
    y: number;
    imageURL: string;
    data: CardData | BurstElementData;
    type: string;
}

export interface GameSummaryData {
    p1: UserData;
    p2: UserData;
    key: string;
    tournament: boolean;
}

export interface GameData {
    p1: boolean;
    ownUser: UserData;
    opponentsUser: UserData;
    spectators: UserData[];
    activePlayer: boolean;
    activeLocation: LocationData;
    engaged: [CreatureData, CreatureData];
    creatureBeingDefendedID: number;
    creatureAttackingID: number;
    combat: boolean;
    hive: boolean;
    movedCreatureThisTurn: boolean;
    hasValidMoves: boolean;
    mandatoryMovesOutstanding: boolean;
    attacks: AttackData[];
    mugic: MugicData[];
    opponentMugic: MugicData[];
    ownAttackDeck: AttackData[];
    opponentAttackDeck: AttackData[];
    ownLocationDeck: LocationData[];
    opponentLocationDeck: LocationData[];
    attackDiscard: AttackDiscardData;
    ownGeneralDiscard: GeneralDiscardData;
    opponentAttackDiscard: AttackDiscardData;
    opponentsGeneralDiscard: GeneralDiscardData;
    choices: ChoiceData[];
    targeting: EffectData;
    board: BoardData;
    burst: BurstElementData[];
    resolving: BurstElementData;
}

export interface UserData {
    username: string;
    discordUserID: string;
    discriminator: string;
    avatar: string;
    chatBan: boolean;
    language: string;
}

export interface ChoiceData {
    type: string;
    value?: string | CardData;
    position?: GamePosition;
}

export interface SpaceData { creatures: CreatureData[]; mirageLocation: LocationData }

export interface DebugData { abilityNames: string[] }

export interface CardData {
    name: string;
    version: string;
    code: string;
    imageURL: string;
    errataText: string;
    cardType: string;
    counters: [string, number][];
    debug: DebugData | null;
}

export interface BoardData { boardSize: number; spaces: SpaceData[] }

export interface CreatureData extends CardData {
    id: number;
    scannedCourage: number;
    scannedPower: number;
    scannedWisdom: number;
    scannedSpeed: number;
    scannedEnergy: number;
    courage: number;
    power: number;
    wisdom: number;
    speed: number;
    energy: number;
    damage: number;
    fire: boolean;
    air: boolean;
    earth: boolean;
    water: boolean;
    tribes: string[];
    mugicCounters: number;
    infected: boolean;
    engaged: boolean;
    validMoves: number[];
    activatedAbilities: ActivatedAbilityData[];
    owner: boolean;
    battlegear: BattlegearData;
}

export interface BattlegearData extends CardData {
    id: number;
    faceUp: boolean;
    revealed: boolean;
    activatedAbilities: ActivatedAbilityData[];
    owner: boolean;
    controller: boolean;
}

export type AttackData = CardData

export interface LocationData extends CardData {
    activatedAbilities: ActivatedAbilityData[];
    owner: boolean;
}

export interface MugicData extends CardData { id: number; revealed: boolean; tribe: string }

export interface ActivatedAbilityData { name: string; restricted: boolean; canPayCost: boolean; effect: EffectData }

export interface EffectData {
    name: string;
    targetTypes: string[];
    targetsOwnDiscard: boolean;
    targetsOpponentsDiscard: boolean;
    targetsOptional: boolean;
    requiresTarget: boolean;
}

export interface TriggeredAbilityData { name: string }

export interface BurstElementData {
    type: string;
    value: AttackData | MugicData | ActivatedAbilityData | TriggeredAbilityData;
    source: CardData;
    effect?: EffectData;
    targetPositions?: GamePosition[];
    strikingPosition?: GamePosition;
    activatingPosition?: GamePosition;
    casterPosition?: GamePosition;
}

export const isBurstElementData = (data: MouseOverData): data is Omit<MouseOverData, 'data'> & { data: BurstElementData }=> {
    return data.type === 'burst';
};

export interface GamePosition { type: string; id: number }

export interface AttackDiscardData {
    attacks: AttackData[];
}

export interface GeneralDiscardData {
    creatures: CreatureData[];
    battlegear: BattlegearData[];
    mugic: MugicData[];
}

export interface DeckMetaDataData {
    id: number;
    name: string;
    deckstring: string;
    format: string;
    valid: number;
    default: number;
}

export interface DeckData {
    creatures: CreatureData[];
    battlegear: BattlegearData[];
    mugic: MugicData[];
    locations: CardData[];
    attacks: CardData[];
}

export interface DeckInfoData extends DeckMetaDataData {
    deck: DeckData;
    issues: string[];
}

export interface MatchData {
    date: Date;
    p1: UserData;
    p2: UserData;
    p1DeckName: string;
    p1Deckstring: string;
    p2DeckName: string;
    p2Deckstring: string;
    p1Winner: number;
    p2Winner: number;
}

export type EmoteMap = Record<number, string>

export interface EmoteData {
    p1?: number;
    p2?: number;
}

export type BoardContent = 'board'
| 'general-discard'
| 'opponent-general-discard'
| 'attack-discard'
| 'opponent-attack-discard';

export type OnSelect = (id: number) => void;

export type ViewDeck = 'ownAttackDeck' | 'opponentAttackDeck' | 'ownLocationDeck' | 'opponentLocationDeck' | null;
