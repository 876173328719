import * as React from 'react';

import { BurstElementData, CardData, MouseOverData } from '../interfaces';

export interface MouseMoveCapturerProps {
    imageURL: string;
    onMouseOverCard: (data: MouseOverData) => void;
    data: CardData | BurstElementData;
    type: string;
}

export class MouseMoveCapturer extends React.Component<MouseMoveCapturerProps> {
    static defaultProps = {
        data: null,
    }

    render() {
        const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (this.props.imageURL) {
                this.props.onMouseOverCard({
                    x: event.pageX,
                    y: event.pageY,
                    imageURL: this.props.imageURL,
                    data: this.props.data,
                    type: this.props.type,
                });
            }
        };
        const onMouseLeave = () => this.props.onMouseOverCard({ x: null, y: null, imageURL: '', data: null, type: '' });

        return(
            <div className={'card-viewer-capturer'} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
                {this.props.children}
            </div>
        );
    }
}
