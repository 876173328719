import * as React from 'react';

import { MatchData } from '../interfaces';

export interface MatchProps {
    data: MatchData;
    onDeck: (deckName: string, deckstring: string) => void;
}

export const Match = (props: MatchProps) => {
    let className = 'match';
    let score = '';
    if (props.data.p1Winner === 1 && props.data.p2Winner === 1) {
        score = '0.5 - 0.5';
        className += ' tie';
    } else if (props.data.p1Winner) {
        score = '1 - 0';
        className += ' p1-win';
    } else if (props.data.p2Winner) {
        score = '0 - 1';
        className += ' p2-win';
    } else {
        score = 'abandoned';
        className += ' tie';
    }
    const date = new Date(props.data.date);
    const dateString = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    return (
        <div className={className}>
            <div className={'player p1'}>
                <div className={'username'}>{props.data.p1.username}</div>
                <div
                    className={'deck-name'}
                    onClick={() => props.onDeck(props.data.p1DeckName, props.data.p1Deckstring)}>
                    {props.data.p1DeckName}
                </div>
            </div>
            <div className={'center-area'}>
                <div className={'score'}>
                    {score}
                </div>
                <div className={'match-date'} title={'Match End Time'}>
                    {dateString}
                </div>
            </div>
            <div className={'player p2'}>
                <div
                    className={'deck-name'}
                    onClick={() => props.onDeck(props.data.p2DeckName, props.data.p2Deckstring)}>
                    {props.data.p2DeckName}
                </div>
                <div className={'username'}>{props.data.p2.username}</div>
            </div>
        </div>
    );
};
