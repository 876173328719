import * as React from 'react';

interface ToggleSwitchProps {
    label: string;
    checked: boolean;
    disabled: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ToggleSwitch({ label, checked, disabled, onChange }: ToggleSwitchProps) {
    return (
        <div className="toggle-switch">
            <label className='switch'>
                <input type='checkbox' 
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="slider"></span>
            </label>
            <span className='label'>{label}</span>
        </div>
    )
}

ToggleSwitch.defaultProps = {
    disabled: false,
};
