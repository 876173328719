import * as React from 'react';

import { CreatureData } from '../interfaces';

export interface CreatureInfoEnergyProps { data: CreatureData }

export const CreatureInfoEnergy = (props: CreatureInfoEnergyProps) => {
    const energy = props.data ? `${props.data.energy - props.data.damage} / ${props.data.energy}` : '';
    const currentEnergyPercent = props.data ?
        Math.round((1 - props.data.damage / props.data.energy) * 1000) / 10 : 100;

    return (
        <div className={'creature-info-energy'}>
            <div className={'creature-info-energy-outline'}>
                <div className={'creature-info-energy-bar'} style={{ width: `${currentEnergyPercent}%` }}>
                </div>
            </div>
            <div className={'creature-info-energy-percent'}>
                {props.data ? `${currentEnergyPercent}%` : null}
            </div>
            <div className={'creature-info-energy-value'}>
                {energy}
            </div>
        </div>
    );
};
