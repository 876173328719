import { faSmile, faSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { EmoteData, EmoteMap, UserData } from '../interfaces';
import { EmoteDropDown } from './EmoteDropDown';
import { UserBadge } from './UserBadge';
const { useState } = React;

interface EmoteProps {
    right: boolean;
    ownUser: UserData;
    opponentsUser: UserData;
    activePlayer: boolean;
    spectate: boolean;
    emoteChoices: number[];
    emoteData: EmoteData;
    emoteMap: EmoteMap;
    opponentMuted: boolean;
    onEmoteMute: (mute: boolean) => void;
    onEmoteSelect: (id: number) => void;
}

export const EmoteButton = (props: EmoteProps) => {
    const { right, spectate } = props;

    if (spectate) return <SpectateBadge {...props} />;

    if (right) return <ToggleMuteEmoteButton {...props} />;

    return <SelectEmoteButton {...props} />;
};

EmoteButton.defaultProps = {
    right: false,
};

const SpectateBadge = (props: EmoteProps) => {
    const { ownUser, opponentsUser, activePlayer, right } = props;

    return (
        <UserBadge
            user={right ? opponentsUser : ownUser}
            activePlayer={right ? !activePlayer : activePlayer}
            right={right}
        />
    );
};

const SelectEmoteButton = (props: EmoteProps) => {
    const { ownUser, activePlayer, ...rest } = props;
    const [dropDown, setDropDown] = useState(false);

    const openEmoteDropDown = () => {
        setDropDown(true);
    };

    const closeEmoteDropDown = () => {
        setDropDown(false);
    };

    return (
        <div>
            <UserBadge
                user={ownUser}
                activePlayer={activePlayer}
                emoteIcon={<span key="emote" className="emote-icon">
                    <FontAwesomeIcon size="lg" icon={faSmile} />
                </span>}
                onClick={openEmoteDropDown}
            />
            {dropDown &&
                <EmoteDropDown {...({ closeEmoteDropDown, ...props })} />
            }
        </div>
    );
};

const ToggleMuteEmoteButton = (props: EmoteProps) => {
    const { opponentsUser, activePlayer, opponentMuted, onEmoteMute } = props;

    return (
        <div>
            <UserBadge
                user={opponentsUser}
                activePlayer={!activePlayer}
                right
                emoteIcon={<span key="emote" className='emote-icon'>
                    <span className="fa-layers fa-fw ">
                        <FontAwesomeIcon icon={faSmile} size="lg" />
                        <FontAwesomeIcon icon={faSlash} size="lg" inverse className={`${opponentMuted ? 'muted' : 'unmuted'}`} />
                    </span>
                </span>}
                onClick={() => onEmoteMute(!opponentMuted)}
            />
        </div>
    );
};
