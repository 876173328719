// Weekend Rotation!
export const isRotationDay = (): boolean => {
    const day = (new Date()).toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        weekday: 'long',
    });
    return day === 'Friday' || day === 'Saturday' || day === 'Sunday';
};

export const isAprilFools = (): boolean => {
    const day = (new Date()).toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        month: 'numeric',
        day: 'numeric',
    });
    return day === '4/1';
};