const SESSION_ID_KEY = 'chaotic-session-id-v2';
const SHOW_DEFAULT_DECKS_KEY = 'chaotic-show-default-decks-id-v1';
const LOGIN_REDIRECT_PATH_KEY = 'chaotic-login-redirect-path-v1';
const OAUTH_STATE_KEY = 'chaotic-oauth-state-v1';
const ROTATION_WARNING_KEY = 'chaotic-rotation-warning';

export const getSessionID = () => {
    return localStorage.getItem(SESSION_ID_KEY);
};

export const setSessionID = (id: string) => {
    localStorage.setItem(SESSION_ID_KEY, id);
};

export const deleteSessionID = () => {
    localStorage.removeItem(SESSION_ID_KEY);
};

export const loggedIn = () => {
    return getSessionID() !== null;
};

export const getShowDefaultDecks = () => {
    return localStorage.getItem(SHOW_DEFAULT_DECKS_KEY) !== 'false';
};

export const toggleShowDefaultDecks = () => {
    localStorage.setItem(SHOW_DEFAULT_DECKS_KEY, `${!getShowDefaultDecks()}`);
};

export const getLoginRedirectPath = () => {
    return localStorage.getItem(LOGIN_REDIRECT_PATH_KEY);
};

export const saveLoginRedirectPath = () => {
    return localStorage.setItem(LOGIN_REDIRECT_PATH_KEY, window.location.pathname);
};

export const deleteLoginRedirectPath = () => {
    return localStorage.removeItem(LOGIN_REDIRECT_PATH_KEY);
};

export const getOAuthState = (): string => {
    return localStorage.getItem(OAUTH_STATE_KEY);
};

export const setOAuthState = (): string => {
    const rand = Math.floor(Math.random() * 10);
    let randStr = '';

    for (let i = 0; i < 20 + rand; i++) {
        randStr += String.fromCharCode(65 + Math.floor(Math.random() * 26));
    }

    localStorage.setItem(OAUTH_STATE_KEY, randStr);

    return randStr;
};

export const getAcceptedRotation = (): boolean => {
    return !!localStorage.getItem(ROTATION_WARNING_KEY);
};

export const setAcceptedRotation = () => {
    return localStorage.setItem(ROTATION_WARNING_KEY, 'true');
};

export const deleteAcceptedRotation = () => {
    return localStorage.removeItem(ROTATION_WARNING_KEY);
};
