export const tribeColor = (tribe: string) => {
    switch (tribe) {
        case 'OverWorld':
            return 'blue';
        case 'UnderWorld':
            return 'red';
        case 'Danian':
            return 'saddlebrown';
        case 'Mipedian':
            return 'yellow';
        case 'M\'arrillian':
            return '#101010';
        default:
            return 'grey';
    }
};
