import * as React from 'react';
import { DeckData } from '../interfaces';

interface PrintDeckProps {
    name: string;
    deck: DeckData;
}

type stats = 'avg' | 'min' | 'max' | 'none';

const PrintDeck = ({ name, deck } : PrintDeckProps) => {
    const [stats, setStats] = React.useState<stats>('avg');

    const convertDiscipline = (stat: number) => {
        if (stats === 'min') {
            return Math.max(stat - 10, 0);
        }
        if (stats === 'max') {
            return stat + 10;
        }
        return stat;
    };

    const convertEnergy = (energy: number) => {
        if (stats === 'min') {
            return Math.max(energy - 5, 0);
        }
        if (stats === 'max') {
            return energy + 5;
        }
        return energy;
    };

    return (
        <>
            <div className="print-stats-toggle no-print">
                <button
                    className={stats === 'min' ? 'active' : ''}
                    onClick={() => setStats('min')}
                >
                    Min
                </button>
                <button
                    className={stats === 'avg' ? 'active' : ''}
                    onClick={() => setStats('avg')}
                >
                    Avg
                </button>
                <button
                    className={stats === 'max' ? 'active' : ''}
                    onClick={() => setStats('max')}
                >
                    Max
                </button>
                <button
                    className={stats === 'none' ? 'active' : ''}
                    onClick={() => setStats('none')}
                >
                    None
                </button>
            </div>
            <div className='print-deck'>
                <div className='print-cards'>
                    {deck.creatures.map((creature, index) => (
                        <div className={'print-image'} key={index}>
                            {(stats !== 'none') && (<div className={'card-stats'}>
                                <div className={'card-courage'}>{convertDiscipline(creature.scannedCourage)}</div>
                                <div className={'card-power'}>{convertDiscipline(creature.scannedPower)}</div>
                                <div className={'card-wisdom'}>{convertDiscipline(creature.scannedWisdom)}</div>
                                <div className={'card-speed'}>{convertDiscipline(creature.scannedSpeed)}</div>
                                <div className={'card-energy'}>{convertEnergy(creature.scannedEnergy)}</div>
                            </div>)}
                            <img src={creature.imageURL} />
                        </div>
                    ))}
                    {deck.battlegear.map((creature, index) => (
                        <div className={'print-image'} key={index}>
                            <img src={creature.imageURL} />
                        </div>
                    ))}
                    {deck.mugic.map((creature, index) => (
                        <div className={'print-image'} key={index}>
                            <img src={creature.imageURL} />
                        </div>
                    ))}
                    {deck.locations.map((creature, index) => (
                        <div className={'print-image'} key={index}>
                            <div className={'print-image-location'}>
                                <img src={creature.imageURL} />
                            </div>
                        </div>
                    ))}
                    {deck.attacks.map((creature, index) => (
                        <div className={'print-image'} key={index}>
                            <img src={creature.imageURL} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PrintDeck;
