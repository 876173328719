import * as React from 'react';

import { CreatureData } from '../interfaces';
import { CreatureInfoElement } from './CreatureInfoElement';

export interface CreatureInfoElementsProps { data: CreatureData }

export const CreatureInfoElements = (props: CreatureInfoElementsProps) => {
    return (
        <div className={'creature-info-elements'}>
            <CreatureInfoElement element={'fire'} active={props.data && props.data.fire} />
            <CreatureInfoElement element={'air'} active={props.data && props.data.air} />
            <CreatureInfoElement element={'earth'} active={props.data && props.data.earth} />
            <CreatureInfoElement element={'water'} active={props.data && props.data.water} />
        </div>
    );
};
