import { faBug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface BugIconProps { tooltip: string }

export const BugIcon = (props: BugIconProps) => {
    return (
        <div className={'bug-icon'}>
            <FontAwesomeIcon icon={faBug} title={props.tooltip} />
        </div>
    );
};
