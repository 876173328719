import { faFistRaised } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import {
    BoardData,
    BurstElementData,
    ChoiceData,
    EffectData,
    GamePosition,
    MouseOverData,
    OnSelect,
    SpaceData,
} from '../interfaces';
import { BugIcon } from './BugIcon';
import { Selectable } from './Selectable';
import { Space } from './Space';

export interface BoardProps {
    action: string;
    choices: ChoiceData[];
    targeting: EffectData;
    selected: GamePosition[];
    mouseOverBurstElement: BurstElementData;
    onSelectSpace: OnSelect;
    onSelectCreature: OnSelect;
    onSelectBattlegear: OnSelect;
    onSelectMirage: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    reversed: boolean;
    combat: boolean;
    hive: boolean;
    data: BoardData;
    creatureBeingDefendedID: number;
    creatureAttackingID: number;
}

export const Board = (props: BoardProps) => {
    const spaces = [...props.data.spaces];
    if (props.reversed) {
        spaces.reverse();
    }
    const columns: SpaceData[][] = [];
    let columnSize = 1;
    for (let i = 0; i < props.data.boardSize; columnSize++) {
        columns.push([]);
        for (let j = 0; j < columnSize; j++, i++) {
            columns[columns.length - 1].push(spaces[i]);
        }
    }
    columnSize--;
    for (let i = 0; i < props.data.boardSize; columnSize--) {
        columns.push([]);
        for (let j = 0; j < columnSize; j++, i++) {
            columns[columns.length - 1].push(spaces[props.data.boardSize + i]);
        }
    }

    let i = props.reversed ? spaces.length - 1 : 0;

    const mouseOverTargets = props.mouseOverBurstElement && props.mouseOverBurstElement.targetPositions || [];
    const strikingPosition = props.mouseOverBurstElement && props.mouseOverBurstElement.strikingPosition;
    const casterPosition = props.mouseOverBurstElement && props.mouseOverBurstElement.casterPosition;
    const activatingPosition = props.mouseOverBurstElement && props.mouseOverBurstElement.activatingPosition;

    let selectedCreatures: number[];
    if (strikingPosition) {
        selectedCreatures = [strikingPosition.id];
    } else if (casterPosition) {
        selectedCreatures = [casterPosition.id];
    } else if (activatingPosition && activatingPosition.type === 'creature') {
        selectedCreatures = [activatingPosition.id];
    } else {
        selectedCreatures = props.selected
            .filter(selected => selected.type === 'creature')
            .map(selected => selected.id);
    }

    // With multi creature spaces, this isn't needed anymore, but could be useful later
    const highlightedCreatures = [props.creatureBeingDefendedID, props.creatureAttackingID];

    const targetedCreatures = mouseOverTargets
        .filter(target => target.type === 'creature')
        .map(target => target.id);

    let selectedBattlegear: number[];
    if (activatingPosition && activatingPosition.type === 'battlegear') {
        selectedBattlegear = [activatingPosition.id];
    } else {
        selectedBattlegear = props.selected
            .filter(selected => selected.type === 'battlegear')
            .map(selected => selected.id);
    }

    const targetedBattlegear = mouseOverTargets
        .filter(target => target.type === 'battlegear')
        .map(target => target.id);

    let mirageSelectable = false;
    if (props.action === 'target') {
        mirageSelectable = props.targeting.targetTypes[props.selected.length] === 'LocationCard';
    }

    const mirageTargeted = props.mouseOverBurstElement
        && props.mouseOverBurstElement.targetPositions
        && props.mouseOverBurstElement.targetPositions.some(target => target.type === 'mirage');

    return (
        <div className={'board'}>
            {columns.map((column, index) =>
                <div className={'column'} key={index}>
                    {column.map(space => {
                        const id = props.reversed ? i-- : i++;

                        const creatures = space.creatures;
                        const owners = creatures.map(creature => creature.owner);
                        const mugicCounters = creatures.map(creature => creature.mugicCounters);
                        const tribes = creatures.map(creature => creature.tribes);

                        let spaceSelectable = false;
                        if (props.mouseOverBurstElement === null) {
                            if (props.action === 'move') {
                                if (props.selected.length > 0) {
                                    spaceSelectable = props.data.spaces
                                        .reduce((creatures, space) => creatures.concat(space.creatures), [])
                                        .filter(creature => creature.id === props.selected[0].id)
                                        .some(creature => creature.validMoves.includes(id));
                                }
                            } else if (props.action === 'choose') {
                                spaceSelectable = props.choices.some(choice =>
                                    choice.position
                                    && choice.position.type === 'space'
                                    && choice.position.id === id);
                            }
                        }

                        const creaturesSelectable = creatures.map(creature => {
                            if (props.mouseOverBurstElement !== null) {
                                return false;
                            }

                            if (props.action === 'move') {
                                if (props.selected.length === 0) {
                                    return creature.validMoves.length > 0;
                                } else {
                                    return creature.id === props.selected[0].id;
                                }
                            } else if (props.action === 'priority') {
                                return creature.owner
                                    && creature.activatedAbilities.some(ability => !ability.restricted && ability.canPayCost);
                            } else if (props.action === 'choose') {
                                return props.choices.some(choice => choice.position
                                    && choice.position.type === 'creature'
                                    && choice.position.id === creature.id);
                            } else if (props.action === 'target') {
                                return !props.targeting.targetsOwnDiscard
                                    && !props.targeting.targetsOpponentsDiscard
                                    && props.targeting.targetTypes[props.selected.length] === 'Creature';
                            }
                        });
                        const creaturesSelected = creatures.map(creature => selectedCreatures.includes(creature.id));
                        const creaturesHighlighted = creatures.map(creature => highlightedCreatures.includes(creature.id));
                        const creaturesTargeted = creatures.map(creature => targetedCreatures.includes(creature.id));

                        const battlegear = creatures.map(creature => creature.battlegear);
                        const battlegearSelectable = battlegear.map(battlegear => {
                            if (props.mouseOverBurstElement !== null || battlegear === null) {
                                return false;
                            }

                            if (props.action === 'priority') {
                                return battlegear.controller
                                    && battlegear.activatedAbilities.some(ability => !ability.restricted && ability.canPayCost);
                            } else if (props.action === 'choose') {
                                return props.choices.some(choice => choice.position
                                        && choice.position.type === 'battlegear'
                                        && choice.position.id === battlegear.id);
                            } else if (props.action === 'target') {
                                return !props.targeting.targetsOwnDiscard
                                    && !props.targeting.targetsOpponentsDiscard
                                    && props.targeting.targetTypes[props.selected.length] === 'Battlegear';
                            }
                        });
                        const battlegearSelected = battlegear.map(battlegear => battlegear && selectedBattlegear.includes(battlegear.id));
                        const battlegearTargeted = battlegear.map(battlegear => battlegear && targetedBattlegear.includes(battlegear.id));

                        const showing = creatures.map((_, index) => {
                            if (mirageTargeted || props.action === 'target' && mirageSelectable) {
                                return 'mirage-location';
                            } else if (battlegearTargeted[index] || (props.action === 'choice' || props.action === 'target') && battlegearSelectable[index]) {
                                return 'battlegear';
                            } else {
                                return 'creature';
                            }
                        });

                        return (
                            <Selectable
                                key={id}
                                id={id}
                                selectable={spaceSelectable}
                                onSelect={props.onSelectSpace}
                                selected={false}
                                type={'space'}>
                                <Space
                                    id={id}
                                    onSelectMirage={props.onSelectMirage}
                                    onSelectCreature={props.onSelectCreature}
                                    onSelectBattlegear={props.onSelectBattlegear}
                                    onMouseOverCard={props.onMouseOverCard}
                                    owners={owners}
                                    tribes={tribes}
                                    mugicCounters={mugicCounters}
                                    creatures={creatures}
                                    creaturesSelectable={creaturesSelectable}
                                    creaturesSelected={creaturesSelected}
                                    creaturesHighlighted={creaturesHighlighted}
                                    creaturesTargeted={creaturesTargeted}
                                    battlegear={battlegear}
                                    battlegearSelectable={battlegearSelectable}
                                    battlegearSelected={battlegearSelected}
                                    battlegearTargeted={battlegearTargeted}
                                    mirageLocation={space.mirageLocation}
                                    mirageSelectable={mirageSelectable}
                                    mirageTargeted={mirageTargeted}
                                    showing={showing} />
                            </Selectable>
                        );
                    })}
                </div>)}
            {props.combat && <FontAwesomeIcon className={'fist-icon'} icon={faFistRaised} title={'Combat'} />}
            {props.hive && <BugIcon tooltip={'Hive'} />}
        </div>
    );
};
