import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

const DISCORD_LINK = 'https://discord.gg/chaotic';

export const DiscordLink = () => (
    <a className={'discord-link'} href={DISCORD_LINK}>
        <FontAwesomeIcon icon={faDiscord} />
    </a>
);
