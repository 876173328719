import * as React from 'react';

import { CardData, ChoiceData, LocationData } from '../interfaces';
import { Card } from './Card';
import { LocationCard } from './LocationCard';
import { TargetIcon } from './TargetIcon';

export interface ChoiceProps { data: ChoiceData; targeted: boolean }

export const Choice = (props: ChoiceProps) => {
    const { type, value } = props.data;

    if (['string', 'number', 'player', 'triggered-ability', 'burst'].includes(type)) {
        return (
            <div className={'choice'}>
                {value}
                {props.targeted && <TargetIcon />}
            </div>
        );
    }

    switch (type) {
        case 'discard-creature':
        case 'discard-battlegear':
        case 'discard-mugic':
        case 'card':
            return <Card data={value as CardData} targeted={props.targeted} />;
        case 'location':
            return <LocationCard data={value as LocationData} />;
        default:
            return <div className={'choice'}>Error</div>;
    }
};

Choice.defaultProps = {
    targeted: false,
};
