import * as React from 'react';

const SocketContext = React.createContext<WebSocket>(null);

export const SocketProvider = ({value, children}) => {
    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
}

export const useSocket = () => {
    return React.useContext(SocketContext);
}
