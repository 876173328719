export const requestNotificationsPermission = async () => {
    if (!['granted', 'denied'].includes(Notification.permission)) {
        await Notification.requestPermission();
    }
};

export const sendNotification = async (message: string) => {
    if (Notification.permission === 'granted') {
        new Notification(message);
    } else if (Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            new Notification(message);
        }
    }
};
