import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { BattlegearData, BurstElementData, CardData, CreatureData, MouseOverData, MugicData, isBurstElementData } from '../interfaces';

export interface CardViewerProps { alt: boolean; data: MouseOverData; spectate: boolean }

export const CardViewer = (props: CardViewerProps) => {
    let annotation: JSX.Element, debug: JSX.Element, text: JSX.Element;
    let card: CardData;
    if (props.data.data) {
        if (isBurstElementData(props.data)) {
            if (props.data.data.type === 'trigger' || props.data.data.type === 'activated-ability') {
                card = props.data.data.source; // The source of the trigger or active is not the value
            } else {
                card = props.data.data.value as CardData;
            }
        } else {
            card = props.data.data as CardData;

            if (card && card.debug) {
                debug = (
                    <div className={'card-viewer-card-debug'}>
                        {card.debug.abilityNames.map((name, index) => <div key={index}>{name}</div>)}
                    </div>
                );
            }
        }
    }

    let cardWidth: number;
    let cardHeight: number;
    if (props.data.type === 'burst' && !card?.cardType) {
        cardWidth = 350;
        cardHeight = 350;
    } else if (['location', 'mirage-location'].includes(card?.cardType ?? props.data.type)) {
        cardWidth = 350;
        cardHeight = 250;
    } else {
        cardWidth = 250;
        cardHeight = 350;
    }

    if (card) {
        if (card.cardType === 'creature' || props.data.type === 'discard-creature') {
            const creature = card as CreatureData;
            const currentEnergy = creature.energy - creature.damage;
            const damaged = creature.damage > 0;
            const energyClassName = 'card-viewer-card-energy' + (damaged && !props.alt ? ' damaged' : '');
            const courageValue = !props.alt ? creature.courage : creature.scannedCourage;
            const powerValue = !props.alt ? creature.power : creature.scannedPower;
            const wisdomValue = !props.alt ? creature.wisdom : creature.scannedWisdom;
            const speedValue = !props.alt ? creature.speed : creature.scannedSpeed;
            const energyValue = !props.alt ? currentEnergy : creature.scannedEnergy;

            annotation = (
                <div>
                    <div className={'card-viewer-card-courage'}>{courageValue}</div>
                    <div className={'card-viewer-card-power'}>{powerValue}</div>
                    <div className={'card-viewer-card-wisdom'}>{wisdomValue}</div>
                    <div className={'card-viewer-card-speed'}>{speedValue}</div>
                    <div className={energyClassName}>{energyValue}</div>
                    <div className={'card-viewer-card-code'}>{creature.code}</div>
                </div>
            );
        } else if (card.cardType === 'battlegear') {
            const battlegear = card as BattlegearData;

            if (!battlegear.faceUp) {
                if (battlegear.revealed) {
                    annotation = (
                        <div>
                            <div className={'card-viewer-card-facedown'}>
                                <FontAwesomeIcon icon={faUndoAlt} />
                            </div>
                            <div className={'card-viewer-card-revealed'}>
                                <FontAwesomeIcon icon={faEye} />
                            </div>
                        </div>
                    );
                } else {
                    annotation = (
                        <div>
                            <div className={'card-viewer-card-facedown' + ((battlegear.owner && !props.spectate) ? ' owner' : '')}>
                                <FontAwesomeIcon icon={faUndoAlt} />
                            </div>
                        </div>
                    );
                }
            }
        } else if (card.cardType === 'mugic') {
            const mugic = card as MugicData;

            if (mugic.revealed) {
                annotation = (
                    <div>
                        <div className={'card-viewer-card-revealed'}>
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                    </div>
                );
            }
        }

        if (card.errataText.length > 0 || card.counters.length > 0) {
            text = (
                <div className={'card-viewer-text'} style={{ width: cardWidth - 10 }}>
                    {card.errataText.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                    {card.counters.length > 0 && (<>
                        <div className={'line'} />
                        <div>Counters:</div>
                        {card.counters.map(([name, count]) => (
                            <div key={name}>{`${name}: ${count}`}</div>
                        ))}
                    </>)}
                </div>
            );
        }
    }

    const offset = 10;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let left: number;
    if (props.data.x > windowWidth / 2) {
        left = props.data.x - 2 * cardWidth - offset;
    } else {
        left = props.data.x + offset;
    }
    let top: number;
    if (props.data.y > 2 * windowHeight / 3) {
        top = props.data.y - cardHeight - offset;
    } else if (props.data.y > windowHeight / 3) {
        top = props.data.y - cardHeight / 2;
    } else {
        top = props.data.y + offset;
    }

    return (
        <div className={'card-viewer'}
            style={{ left, top }}>
            <div
                className={'card-viewer-card'}
                style={{ width: cardWidth, height: cardHeight, backgroundImage: `url(${props.data.imageURL})` }}>
                {annotation}
                {debug}
            </div>
            {text}
        </div>
    );
};

CardViewer.defaultProps = {
    spectate: false,
};
