import * as React from 'react';
import { Link } from 'react-router-dom';

import { DeckMetaDataData } from '../interfaces';
import { CustomSettings, CustomSettingsProps } from './CustomSettings';
import { DefaultDecksButton } from './DefaultDecksButton';
import { HomeButton } from './HomeButton';
import { DecksList } from './DecksList';

export interface DeckPickerProps extends Omit<CustomSettingsProps, 'create'> {
    decks: DeckMetaDataData[];
    format: string;
    onSelect: (deckID: number) => void;
}

export class DeckPicker extends React.Component<DeckPickerProps> {
    static defaultProps = {
        format: null,
        gameSettings: {},
        gameKey: null
    }

    constructor(props: DeckPickerProps) {
        super(props);
    }

    render() {
        return (
            <div className={'deck-picker'}>
                <div className={'title-row'}>
                    <div className={'left'}>
                        <HomeButton />
                        <div className={'title'}>Pick a deck</div>
                    </div>
                    <div className={'right'}>
                        <DefaultDecksButton onClick={() => this.forceUpdate()} />
                    </div>
                </div>
                <DecksList
                    decks={this.props.decks}
                    format={this.props.format}
                    onSelect={this.props.onSelect} />
                <Link to={'/deckbuilder'} className={'deck-builder-button'}>
                    <div>Deck Builder</div>
                </Link>
                {(this.props.gameKey && Object.keys(this.props.gameSettings).length > 0) && (
                    <CustomSettings {...this.props} />
                )}
            </div>
        );
    }
}
