import * as React from 'react';

import { EmoteData, EmoteMap, UserData } from '../interfaces';
import { EmoteButton } from './EmoteButton';

export interface InfoAreaProps {
    action: string;
    message: string;
    ownUser: UserData;
    opponentsUser: UserData;
    activePlayer: boolean;
    spectate: boolean;
    emoteData: EmoteData;
    emoteMap: EmoteMap;
    emoteChoices: number[];
    opponentMuted: boolean;
    onEmoteMute: (mute: boolean) => void;
    onEmoteSelect: (id: number) => void;
}


export const InfoArea = (props: InfoAreaProps) => {
    const { action, message, ...rest } = props;

    return (
        <div className={'info-area'}>
            <EmoteButton {...rest} />
            <div className={'message' + (action === 'none' ? ' waiting' : '')}>{message}</div>
            <EmoteButton {...rest} right />
        </div>
    );
};
