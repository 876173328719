import * as React from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';

import { NODE_ENV } from '../App';
import { deleteSessionID, getSessionID, loggedIn } from '../helpers/local-storage';
import {
    BoardContent,
    BurstElementData,
    DeckData,
    DeckMetaDataData,
    GameData,
    GamePosition,
    MouseOverData,
    EmoteData,
    EmoteMap,
    ViewDeck,
} from '../interfaces';
import { BoardArea } from './BoardArea';
import { BurstArea } from './BurstArea';
import { CardViewer } from './CardViewer';
import { ConcedeButton } from './ConcedeButton';
import { CreatureInfoArea } from './CreatureInfoArea';
import { DeckInfoArea } from './DeckInfoArea';
import { DeckPicker } from './DeckPicker';
import { DiscardButtonsArea } from './DiscardButtonsArea';
import { FullScreenMessage } from './FullScreenMessage';
import { InfoArea } from './InfoArea';
import { LoginRedirect } from './LoginRedirect';
import { PassButton } from './PassButton';
import { ReportButton } from './ReportButton';
import { UserArea } from './UserArea';
import { EmoteDisplay } from './EmoteDisplay';
import { GameSettings } from './CustomSettings';

export interface GameProps {
    socket: WebSocket;
    sessionID: string;
    spectate: boolean;
    reopenSocket: () => void;
}

export interface GameState {
    // Internal to the game client
    boardContent: BoardContent;
    selected: GamePosition[];
    mouseOverData: MouseOverData;
    alt: boolean;
    deck: DeckData;
    copiedLink: boolean;
    redirectToHome: boolean;
    viewDeck: ViewDeck;
    // State from the server
    gameOver: boolean;
    message: string;
    link: string;
    spectateLink: string;
    error: string;
    chooseDeck: boolean;
    deckMetaDatas: DeckMetaDataData[];
    action: string;
    logs: string[];
    chatMessages: string[];
    game: GameData;
    gameSettings: GameSettings;
    emote: EmoteData;
    emoteMap: EmoteMap;
    emoteChoices: number[];
    opponentMuted: boolean;
}

const SPACE_KEY_CODE = 32;
const ALT_KEY_CODE = 18;
const ONE_KEY_CODE = 49;

export class Game extends React.Component<GameProps & RouteComponentProps, GameState> {
    static defaultProps = {
        spectate: false,
    }

    constructor(props: GameProps & RouteComponentProps) {
        super(props);

        this.state = {
            // State from the client
            boardContent: 'board',
            selected: [],
            mouseOverData: { x: null, y: null, imageURL: '', data: null, type: '' },
            alt: false,
            deck: null,
            copiedLink: false,
            redirectToHome: false,
            viewDeck: null,
            // State from the server
            gameOver: false,
            message: null,
            link: null,
            spectateLink: null,
            error: null,
            chooseDeck: false,
            deckMetaDatas: [],
            action: null,
            logs: [],
            chatMessages: [],
            emoteMap: {},
            emote: {},
            emoteChoices: [],
            opponentMuted: false,
            gameSettings: {},
            game: {
                p1: true,
                ownUser: null,
                opponentsUser: null,
                spectators: [],
                activePlayer: null,
                activeLocation: null,
                engaged: [null, null],
                creatureBeingDefendedID: null,
                creatureAttackingID: null,
                combat: false,
                hive: false,
                movedCreatureThisTurn: false,
                hasValidMoves: true,
                mandatoryMovesOutstanding: false,
                attacks: null,
                mugic: null,
                opponentMugic: null,
                ownAttackDeck: [],
                opponentAttackDeck: [],
                ownLocationDeck: [],
                opponentLocationDeck: [],
                attackDiscard: { attacks: [] },
                ownGeneralDiscard: { creatures: [], battlegear: [], mugic: [] },
                opponentAttackDiscard: { attacks: [] },
                opponentsGeneralDiscard: { creatures: [], battlegear: [], mugic: [] },
                choices: null,
                targeting: null,
                board: null,
                burst: null,
                resolving: null,
            },
        };

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onError = this.onError.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.connectToGame = this.connectToGame.bind(this);
        this.onClickLink = this.onClickLink.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.onPass = this.onPass.bind(this);
        this.onReport = this.onReport.bind(this);
        this.onConcede = this.onConcede.bind(this);
        this.onSelectSpace = this.onSelectSpace.bind(this);
        this.onSelectCreature = this.onSelectCreature.bind(this);
        this.onSelectBattlegear = this.onSelectBattlegear.bind(this);
        this.onSelectMugic = this.onSelectMugic.bind(this);
        this.onSelectMirage = this.onSelectMirage.bind(this);
        this.onSelectAttack = this.onSelectAttack.bind(this);
        this.onSelectLocation = this.onSelectLocation.bind(this);
        this.onSelectChoice = this.onSelectChoice.bind(this);
        this.onSelectBurstElement = this.onSelectBurstElement.bind(this);
        this.onSelectTarget = this.onSelectTarget.bind(this);
        this.onSubmitChat = this.onSubmitChat.bind(this);
        this.onSetBoardContent = this.onSetBoardContent.bind(this);
        this.onMouseOverCard = this.onMouseOverCard.bind(this);
        this.onViewDeck = this.onViewDeck.bind(this);
    }

    componentDidMount() {
        this.props.socket.onerror = this.onError;
        this.props.socket.onclose = (() => this.setState({ action: 'none' })).bind(this);
        this.props.socket.onopen = this.onOpen;
        this.props.socket.onmessage = this.onMessage;

        if (this.props.socket.readyState === WebSocket.OPEN) {
            this.onOpen();
        }

        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
    }

    componentDidUpdate(_prevProps, prevState: GameState) {
        if (prevState.action !== this.state.action) {
            this.setState({ viewDeck: null });
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (NODE_ENV === 'development') {
            console.log('keydown', event.keyCode);
        }

        if (event.target instanceof HTMLInputElement) {
            return;
        }

        if (event.keyCode === SPACE_KEY_CODE && this.state.game.board) {
            if (this.canPass()) {
                this.onPass();
            }
            event.preventDefault();
        } else if (event.keyCode === ALT_KEY_CODE) {
            this.setState({ alt: true });
        } else if (event.keyCode >= ONE_KEY_CODE && event.keyCode <= ONE_KEY_CODE + 8) {
            const id = event.keyCode - ONE_KEY_CODE;
            if (this.state.action === 'strike' && id < this.state.game.attacks.length) {
                this.onSelectAttack(id);
            } else if (this.state.action === 'choose' && id < this.getUserAreaChoices().length) {
                this.onSelectChoice(id);
            }
        }
    }

    onKeyUp(event: KeyboardEvent) {
        if (NODE_ENV === 'development') {
            console.log('keyup', event.keyCode);
        }

        if (event.target instanceof HTMLInputElement) {
            return;
        }

        if (event.keyCode === ALT_KEY_CODE) {
            this.setState({ alt: false });
        }
    }

    onError() {
        this.setState({ message: 'Failed to connect to server' });
    }

    onOpen() {
        const key = this.props.match.params['game'];
        const sessionID = getSessionID();
        this.setState({ message: 'Finding Game' }, () => {
            if (this.props.spectate) {
                this.props.socket.send(`spectate|${key}|${sessionID}`);
            } else {
                this.props.socket.send(`check|${key}|${sessionID}`);
            }
        });
    }

    connectToGame(deckID: number) {
        const key = this.props.match.params['game'];
        const sessionID = getSessionID();
        this.setState({ message: 'Connecting to Game' }, () => {
            this.props.socket.send(`connect|${key}|${sessionID}|${deckID}`);
        });
    }

    onClickLink(event: React.MouseEvent<HTMLInputElement>) {
        event.currentTarget.select();
        event.currentTarget.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.setState({ copiedLink: true });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        if (NODE_ENV === 'development') {
            console.log('message:', data);
        }
        if (data.logout) {
            deleteSessionID();
        }
        if (data.emote) {
            // emote doesn't update client state
            this.setState({ emote: data.emote });
            return;
        }

        const newState: GameState = { ...this.state, message: null };
        Object.keys(data)
            .filter(key => !['logs', 'chatMessages'].includes(key) && Object.keys(this.state).includes(key))
            .forEach(key => newState[key] = data[key]);
        if (data.logs) {
            newState.logs = [...this.state.logs, ...data.logs];
        }
        if (data.chatMessages) {
            newState.chatMessages = [...this.state.chatMessages, ...data.chatMessages];
        }
        newState.chooseDeck = !!data.chooseDeck;
        newState.mouseOverData = { x: null, y: null, imageURL: '', data: null, type: '' };
        this.setState(newState);
    }

    onPass() {
        if (this.state.gameOver || this.props.spectate) {
            this.setState({ redirectToHome: true }, () => this.props.reopenSocket());
        } else {
            this.setState({ selected: [] }, () => {
                this.props.socket.send('pass');
            });
        }
    }

    onReport(bug: string) {
        this.props.socket.send(`report|${bug}`);
    }

    onConcede() {
        this.props.socket.send('concede');
    }

    onSelectSpace(id: number) {
        this.clearMouseOverData();
        if (this.state.action === 'move') {
            if (this.state.selected.length === 1) {
                this.props.socket.send(`move|${this.state.selected[0].id}|${id}`);
                this.setState({ selected: [] });
            }
        } else if (this.state.action === 'choose') {
            const choiceIndex = this.state.game.choices.findIndex(choice => choice.position
                && choice.position.type === 'space'
                && choice.position.id === id);
            this.props.socket.send(`choose|${choiceIndex}`);
        }
    }

    onSelectCreature(id: number) {
        this.clearMouseOverData();
        if (this.state.action === 'move') {
            if (this.state.selected.map(selected => selected.id).includes(id)) {
                this.setState({ selected: this.state.selected.filter(selected => selected.id !== id) });
            } else {
                this.setState({ selected: this.state.selected.concat({ type: 'creature', id }) });
            }
        } else if (this.state.action === 'priority') {
            this.props.socket.send(`ability|creature|${id}`);
        } else if (this.state.action === 'choose') {
            const choiceIndex = this.state.game.choices.findIndex(choice => choice.position
                && choice.position.type === 'creature'
                && choice.position.id === id);
            this.props.socket.send(`choose|${choiceIndex}`);
        } else if (this.state.action === 'target') {
            this.addTarget('creature', id);
        }
    }

    onSelectBattlegear(id: number) {
        this.clearMouseOverData();
        if (this.state.action === 'priority') {
            this.props.socket.send(`ability|battlegear|${id}`);
        } else if (this.state.action === 'choose') {
            const choiceIndex = this.state.game.choices.findIndex(choice => choice.position
                && choice.position.type === 'battlegear'
                && choice.position.id === id);
            this.props.socket.send(`choose|${choiceIndex}`);
        } else if (this.state.action === 'target') {
            this.addTarget('battlegear', id);
        }
    }

    onSelectMugic(id: number) {
        if (this.state.action === 'priority') {
            this.props.socket.send(`mugic|${id}`);
        } else if (this.state.action === 'choose') {
            const choiceIndex = this.state.game.choices.findIndex(choice =>
                choice.position && choice.position.type === 'mugic' && choice.position.id === id);
            this.props.socket.send(`choose|${choiceIndex}`);
        }
    }

    onSelectMirage(id: number) {
        this.clearMouseOverData();
        if (this.state.action === 'target') {
            this.addTarget('mirage', id);
        }
    }

    onSelectAttack(id: number) {
        this.clearMouseOverData();
        this.props.socket.send(`strike|${id}`);
    }

    onSelectLocation(id: number) {
        this.clearMouseOverData();
        this.props.socket.send(`ability|location|${id}`);
    }

    onSelectChoice(id: number) {
        this.clearMouseOverData();
        const index = this.state.game.choices.indexOf(this.getUserAreaChoices()[id]);
        this.props.socket.send(`choose|${index}`);
    }

    onSelectBurstElement(id: number) {
        this.clearMouseOverData();
        this.addTarget('burst', id);
    }

    onSelectTarget(type: string) { // For targeting players or targetting cards in discard piles
        return (id: number) => {
            this.clearMouseOverData();
            this.addTarget(type, id);
        };
    }

    addTarget(type: string, id: number) {
        if (type === 'submit') {
            this.submitTargets();
        } else {
            this.setState({ selected: this.state.selected.concat({ type, id }) }, () => {
                if (this.state.selected.length === this.state.game.targeting.targetTypes.length) {
                    this.submitTargets();
                }
            });
        }
    }

    submitTargets() {
        this.props.socket.send(this.state.selected.reduce((message, target) =>
            message.concat(`|${target.type}|${target.id}`), 'target'));
        this.setState({ selected: [] });
    }

    onSubmitChat(chat: string) {
        const message = chat.replace('|', ' ');
        this.props.socket.send(`chat|${message}`);
    }

    clearEmote = (p: 'p1' | 'p2') => {
        const { emote } = this.state;
        emote[p] = undefined;
        this.setState({ emote });
    }

    onEmoteSelect = (emote: number) => {
        this.props.socket.send(`emote|${emote}`);
    }

    onEmoteMute = (mute: boolean) => {
        this.setState({ opponentMuted: mute });
        const action = (mute) ? 'mute' : 'unmute';
        this.props.socket.send(`mute|${action}`);
    }

    onSetBoardContent(boardContent: BoardContent) {
        this.setState({ boardContent });
    }

    onMouseOverCard(data: MouseOverData) {
        this.setState({ mouseOverData: data });
    }

    clearMouseOverData() {
        this.setState({ mouseOverData: { x: null, y: null, imageURL: '', data: null, type: '' } });
    }

    getUserAreaChoices() {
        return this.state.game.choices
            ? this.state.game.choices.filter(choice => !['creature', 'battlegear', 'mugic', 'space'].includes(choice.type))
            : [];
    }

    canPass() {
        return this.props.spectate
            || this.state.gameOver
            || this.state.action === 'move' && (!this.state.game.hasValidMoves || this.state.game.movedCreatureThisTurn && !this.state.game.mandatoryMovesOutstanding)
            || this.state.action === 'target' && !this.state.game.targeting.requiresTarget
            || this.state.action === 'priority';
    }

    getPassButtonText() {
        if (this.state.gameOver || this.props.spectate) {
            return 'Home';
        } else if (this.state.action === 'move' && (!this.state.game.hasValidMoves || this.state.game.movedCreatureThisTurn && !this.state.game.mandatoryMovesOutstanding)) {
            return 'End Turn';
        } else if (this.state.action === 'target' && !this.state.game.targeting.requiresTarget) {
            return 'Cancel';
        } else {
            return 'Pass';
        }
    }

    onViewDeck(deck: ViewDeck) {
        this.setState({ viewDeck: deck });
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        if (this.state.redirectToHome) {
            return <Redirect to={'/'} />;
        }

        if (this.props.socket.readyState === WebSocket.CLOSED && !this.state.gameOver) {
            return <FullScreenMessage message={'Connection to Server Closed'} />;
        }

        const mouseOverBurstElement = this.state.mouseOverData.type === 'burst'
            ? this.state.mouseOverData.data as BurstElementData
            : null;
        const mouseOverTargets = mouseOverBurstElement && mouseOverBurstElement.targetPositions || [];
        const userAreaChoices = this.getUserAreaChoices();

        const spectateRelativeLink = this.state.spectateLink
            && this.state.spectateLink.substring(this.state.spectateLink.lastIndexOf('/', this.state.spectateLink.lastIndexOf('/') - 1));

        const className = 'game' + (this.props.spectate ? ' spectate' : '');
        if (this.state.game.board) {
            return (
                <div className={className}>
                    <div className={'left-column'}>
                        <InfoArea
                            action={this.state.action}
                            message={this.state.message}
                            ownUser={this.state.game.ownUser}
                            opponentsUser={this.state.game.opponentsUser}
                            activePlayer={this.state.game.activePlayer}
                            spectate={this.props.spectate}
                            emoteData={this.state.emote}
                            emoteMap={this.state.emoteMap}
                            emoteChoices={this.state.emoteChoices}
                            opponentMuted={this.state.opponentMuted}
                            onEmoteMute={this.onEmoteMute}
                            onEmoteSelect={this.onEmoteSelect} />
                        <EmoteDisplay
                            emoteData={this.state.emote}
                            emoteMap={this.state.emoteMap}
                            clearEmote={this.clearEmote} />
                        <BoardArea
                            boardContent={this.state.boardContent}
                            action={this.state.action}
                            reversed={!this.state.game.p1}
                            combat={this.state.game.combat}
                            hive={this.state.game.hive}
                            board={this.state.game.board}
                            creatureBeingDefendedID={this.state.game.creatureBeingDefendedID}
                            creatureAttackingID={this.state.game.creatureAttackingID}
                            ownGeneralDiscard={this.state.game.ownGeneralDiscard}
                            opponentsGeneralDiscard={this.state.game.opponentsGeneralDiscard}
                            attackDiscard={this.state.game.attackDiscard}
                            opponentAttackDiscard={this.state.game.opponentAttackDiscard}
                            choices={this.state.game.choices}
                            mugic={this.state.game.mugic}
                            opponentMugic={this.state.game.opponentMugic}
                            targeting={this.state.game.targeting}
                            selected={this.state.selected}
                            mouseOverBurstElement={mouseOverBurstElement}
                            onSelectSpace={this.onSelectSpace}
                            onSelectCreature={this.onSelectCreature}
                            onSelectBattlegear={this.onSelectBattlegear}
                            onSelectMugic={this.onSelectMugic}
                            onSelectMirage={this.onSelectMirage}
                            onMouseOverCard={this.onMouseOverCard} />
                        <DiscardButtonsArea
                            boardContent={this.state.boardContent}
                            ownGeneralDiscard={this.state.game.ownGeneralDiscard}
                            opponentsGeneralDiscard={this.state.game.opponentsGeneralDiscard}
                            attackDiscard={this.state.game.attackDiscard}
                            opponentAttackDiscard={this.state.game.opponentAttackDiscard}
                            onSetBoardContent={this.onSetBoardContent} />
                        <UserArea
                            action={this.state.action}
                            selected={this.state.selected}
                            mouseOverTargets={mouseOverTargets}
                            mouseOverBurstElement={mouseOverBurstElement}
                            userAreaChoices={userAreaChoices}
                            viewDeck={this.state.viewDeck}
                            onViewDeck={this.onViewDeck}
                            onSelectTarget={this.onSelectTarget}
                            onSelectChoice={this.onSelectChoice}
                            onSelectAttack={this.onSelectAttack}
                            onSelectLocation={this.onSelectLocation}
                            onMouseOverCard={this.onMouseOverCard}
                            {...this.state.game} />
                    </div>
                    <div className={'right-column'}>
                        <div className={'action-buttons'}>
                            <PassButton
                                enabled={this.canPass()}
                                text={this.getPassButtonText()}
                                onPass={this.onPass} />
                            {!this.props.spectate &&
                                <ReportButton onReport={this.onReport} />}
                            {!this.props.spectate &&
                                <ConcedeButton onConcede={this.onConcede} />}
                        </div>
                        <BurstArea
                            action={this.state.action}
                            targeting={this.state.game.targeting}
                            selected={this.state.selected}
                            mouseOverTargets={mouseOverTargets}
                            onSubmitChat={this.onSubmitChat}
                            onSelectBurstElement={this.onSelectBurstElement}
                            onMouseOverCard={this.onMouseOverCard}
                            burst={this.state.game.burst}
                            resolving={this.state.game.resolving}
                            logs={this.state.logs}
                            chatMessages={this.state.chatMessages}
                            spectate={this.props.spectate}
                            ownUser={this.state.game.ownUser}
                            opponentUser={this.state.game.opponentsUser}
                            spectators={this.state.game.spectators}
                        />
                        <DeckInfoArea
                            ownAttackDeckCount={this.state.game.ownAttackDeck.length}
                            opponentAttackDeckCount={this.state.game.opponentAttackDeck.length}
                            ownLocationDeckCount={this.state.game.ownLocationDeck.length}
                            opponentLocationDeckCount={this.state.game.opponentLocationDeck.length}
                            onViewDeck={this.onViewDeck}
                            spectate={this.props.spectate}
                        />
                        <CreatureInfoArea
                            reversed={this.state.game.engaged[0] && !this.state.game.engaged[0].owner}
                            mouseOverData={this.state.mouseOverData}
                            engaged={this.state.game.engaged} />
                    </div>
                    {this.state.mouseOverData.imageURL.length > 0 && <CardViewer alt={this.state.alt} data={this.state.mouseOverData} spectate={this.props.spectate} />}
                </div>
            );
        } else if (this.state.error) {
            return <FullScreenMessage message={this.state.error} />;
        } else if (this.state.message) {
            return (
                <FullScreenMessage message={this.state.message}>
                    {this.state.link &&
                        <input
                            className={'waiting-link'}
                            value={this.state.link}
                            onClick={this.onClickLink}
                            readOnly />}
                    {this.state.link &&
                        <div>{this.state.copiedLink ? 'Copied!' : 'Click to copy'}</div>}
                    {this.state.spectateLink &&
                        <Link to={spectateRelativeLink}>{this.state.spectateLink}</Link>}
                </FullScreenMessage>
            );
        } else if (this.state.chooseDeck) {
            return <DeckPicker
                decks={this.state.deckMetaDatas}
                onSelect={this.connectToGame}
                gameSettings={this.state.gameSettings}
                gameKey={this.props.match.params['game']}
                />;
        } else {
            return <FullScreenMessage message={'Invalid State'} />;
        }
    }
}
