import * as React from 'react';

import { CardData, ChoiceData, MouseOverData, OnSelect } from '../interfaces';
import { Choice } from './Choice';
import { MouseMoveCapturer } from './MouseMoveCapturer';
import { Selectable } from './Selectable';

export interface ChoicesProps {
    onSelect: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    data: ChoiceData[];
}

export const Choices = (props: ChoicesProps) => {
    return (
        <div className={'choices'}>
            {props.data.map((choice, index) =>
                <Selectable
                    key={index}
                    id={index}
                    selectable
                    onSelect={props.onSelect}>
                    {choice.type === 'string' || choice.type === 'player' || choice.type === 'triggered-ability' || choice.type === 'burst' ?
                        <Choice data={choice} /> :
                        <MouseMoveCapturer
                            imageURL={(choice.value as CardData).imageURL}
                            onMouseOverCard={props.onMouseOverCard}
                            data={choice.value as CardData}
                            type={choice.type}>
                            <Choice data={choice} />
                        </MouseMoveCapturer>}
                </Selectable>)}
        </div>
    );
};
