
import { faClone } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowDown,
    faArrowUp,
    faDownload,
    faExclamationCircle,
    faLink,
    faShieldAlt,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { DeckMetaDataData } from '../interfaces';
import { useDrag, useDrop } from 'react-dnd';

export interface DecksListItemProps {
    index: number;
    showIcons: boolean;
    deckMetaData: DeckMetaDataData;
    onSelect: (deckID: number) => void;
    onMove: (deckID: number, dragIndex: number, dropIndex: number) => void;
    onShare: (deckID: number) => void;
    onDuplicate: (deckID: number) => void;
    onDelete: (deckID: number) => void;
}

export const DecksListItem = (props: DecksListItemProps) => {
    const ref = React.useRef(null);

    const { deckMetaData, index } = props;

    const [{ handlerId }, drop] = useDrop({
        accept: "deck",
        collect(monitor) {
          return {
            handlerId: monitor.getHandlerId(),
          }
        },
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            // @ts-ignore
            const dragIndex = item.index;
            const dropIndex = index;
            // Don't replace items with themselves
            if (dragIndex === dropIndex) {
                return;
            }
            if (deckMetaData.default === 0) {
                props.onMove(deckMetaData.id, dragIndex, dropIndex);
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "deck",
        item: () => {
            return { id: deckMetaData.id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    if (props.onMove !== null) {
        drag(drop(ref));
    }

    const opacity = isDragging ? 0 : 1

    return (
        <div
            ref={ref}
            className={'deck'}
            style={{ opacity }}
            data-handler-id={handlerId}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => props.onSelect(deckMetaData.id)}
        >
            <div>{`${index + 1}. ${deckMetaData.name}`}</div>
            {props.showIcons && (
                <div className={'buttons'}>
                    {deckMetaData.valid === 0 && (
                        <div className={'invalid-icon'} title={'Invalid Deck'}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </div>
                    )}
                    {props.onMove !== null && deckMetaData.default === 0 && index > 0 && (
                        <div
                            title={'Move Deck Up'}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                props.onMove(deckMetaData.id, index, index - 1);
                                event.stopPropagation();
                            }}>
                            <FontAwesomeIcon icon={faArrowUp} />
                        </div>
                    )}
                    {props.onMove !== null && deckMetaData.default === 0 && (
                        <div
                            title={'Move Deck Down'}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                props.onMove(deckMetaData.id, index, index + 1);
                                event.stopPropagation();
                            }}>
                            <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                    )}
                    {deckMetaData.name && deckMetaData.deckstring && (
                        <div className={'download-icon'} title={'Download Deck'}>
                            <a href={`data:text/plain;charset=utf-8,${encodeURIComponent(deckMetaData.deckstring.replace(/ /g, '\n'))}`}
                                download={`${deckMetaData.name}.txt`}
                                onClick={event => event.stopPropagation()}>
                                <FontAwesomeIcon icon={faDownload} />
                            </a>
                        </div>
                    )}
                    {props.onShare !== null && (
                        <div
                            title={'Get Shareable Deck Link'}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                props.onShare(deckMetaData.id);
                                event.stopPropagation();
                            }}>
                            <FontAwesomeIcon icon={faLink} />
                        </div>
                    )}
                    {props.onDuplicate !== null && (
                        <div
                            title={'Duplicate Deck'}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                props.onDuplicate(deckMetaData.id);
                                event.stopPropagation();
                            }}>
                            <FontAwesomeIcon icon={faClone} />
                        </div>
                    )}
                    {props.onDelete !== null && deckMetaData.default === 0 && (
                        <div
                            title={'Delete Deck'}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                if (confirm(`Do you want to delete ${deckMetaData.name}?`)) {
                                    props.onDelete(deckMetaData.id);
                                }
                                event.stopPropagation();
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    )}
                    {deckMetaData.default === 1 && (
                        <div className={'default-icon'} title={'Default Deck'}>
                            <FontAwesomeIcon icon={faShieldAlt} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
